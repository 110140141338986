import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';

 const PageTitle = (props) => {


    var currentRoutes = window.location.href.split('/');
    var pageRoute = currentRoutes[currentRoutes.length - 1];
    // console.log(pageRoute);
    
    // useEffect(() => {

    //     switch (pageRoute) {
    //         case "how-to-hire-a-full-stack-developer":
    //             document.title = `Hire Dedicated Remote Full
    //             Stack Developer India -
    //             Code1 Dev`;

    //             document.querySelectorAll('[property="og:title"]')[0].content = `Hire Dedicated Remote Full
    //             Stack Developer India -
    //             Code1 Dev`;

    //             document.querySelectorAll('[property="og:description"]')[0].content = `Are You looking for remote full-stack
    //             engineer Jobs? We hire dedicated
    //             fresher, junior& experienced web
    //             developers for clients with a 3-5 days
    //             simple process
    //             `;
    //             document.querySelectorAll('meta[name="description"]')[0].content = `Are You looking for remote full-stack
    //             engineer Jobs? We hire dedicated
    //             fresher, junior& experienced web
    //             developers for clients with a 3-5 days
    //             simple process
    //             `;
    //             break;
    //         case "how-to-hire-data-engineers":
    //             document.title = `Hire Remote Big Data
    //             Engineer in India - Code1
    //             Dev
    //             `;

    //             document.querySelectorAll('[property="og:title"]')[0].content = `Hire Remote Big Data
    //             Engineer in India - Code1
    //             Dev
    //             `;

    //             document.querySelectorAll('[property="og:description"]')[0].content = `Looking for Remote full-time big Data
    //             Engineer jobs in India. We are
    //             CODE1 Dev Hiring IN ~7 Days both
    //             fresher and experienced for clients
    //             with a simple process.
    //             `;
    //             document.querySelectorAll('meta[name="description"]')[0].content = `Looking for Remote full-time big Data
    //             Engineer jobs in India. We are
    //             CODE1 Dev Hiring IN ~7 Days both
    //             fresher and experienced for clients
    //             with a simple process.
    //             `;
    //             break;
    //         case "how-to-hire-a-devops-engineer":
    //             document.title = `Hire Remote Devops
    //             Engineer With Easy
    //             Process - Code1 Dev`;

    //             document.querySelectorAll('[property="og:title"]')[0].content = `Hire Remote Devops
    //             Engineer With Easy
    //             Process - Code1 Dev`;

    //             document.querySelectorAll('[property="og:description"]')[0].content = `Explore top Aws DevOps Jobs
    //             opportunities from Top Companies &
    //             Startups. We are hiring remote
    //             Devops Engineer for a reputed MNC
    //             with 0-10 yrs experience`;
    //             document.querySelectorAll('meta[name="description"]')[0].content = `Explore top Aws DevOps Jobs
    //             opportunities from Top Companies &
    //             Startups. We are hiring remote
    //             Devops Engineer for a reputed MNC
    //             with 0-10 yrs experience`;
    //             break;
        
    //         case "how-to-hire-an-angular-developer":
    //             document.title = `Hire Remote Angular JS
    //             Developers In 7 Days With
    //             Code1 Dev
    //             `;

    //             document.querySelectorAll('[property="og:title"]')[0].content = `Hire Remote Angular JS
    //             Developers In 7 Days With
    //             Code1 Dev
    //             `;

    //             document.querySelectorAll('[property="og:description"]')[0].content = `Hire Remote freelance or work from
    //             Home Angular JS developers on the
    //             basis of a full-time job with 7 Days
    //             Hiring Process Work with the new
    //             technologies and skills
    //             `;
    //             document.querySelectorAll('meta[name="description"]')[0].content = `Hire Remote freelance or work from
    //             Home Angular JS developers on the
    //             basis of a full-time job with 7 Days
    //             Hiring Process Work with the new
    //             technologies and skills
    //             `;
    //             break;
        
    //         case "dot-net":
    //             document.title = `Remote .NET Developer
    //             Jobs with Top Companies |
    //             Apply
    //             `;

    //             document.querySelectorAll('[property="og:title"]')[0].content = `Remote .NET Developer
    //             Jobs with Top Companies |
    //             Apply
    //             `;

    //             document.querySelectorAll('[property="og:description"]')[0].content = `Enjoy higher salaries with remote
    //             full-stack .NET developer jobs at top
    //             companies. We hire developers for
    //             the clients on full-time basis with a
    //             simple 7 days Process
    //             `;
    //             document.querySelectorAll('meta[name="description"]')[0].content = `Enjoy higher salaries with remote
    //             full-stack .NET developer jobs at top
    //             companies. We hire developers for
    //             the clients on full-time basis with a
    //             simple 7 days Process
    //             `;
    //             break;
        
    //         case "sap-consultant":
    //             document.title = `Sap Remote Work From
    //             Home & Flexible Jobs |
    //             Code1 Dev
    //             `;

    //             document.querySelectorAll('[property="og:title"]')[0].content = `Sap Remote Work From
    //             Home & Flexible Jobs |
    //             Code1 Dev
    //             `;

    //             document.querySelectorAll('[property="og:description"]')[0].content = `We Hire Remote SAP consultants
    //             developer/ jobs who implement SAP
    //             systems for business clients that
    //             determine the clients business
    //             needs, create SAP solutions`;

    //             document.querySelectorAll('meta[name="description"]')[0].content = `We Hire Remote SAP consultants
    //             developer/ jobs who implement SAP
    //             systems for business clients that
    //             determine the clients business
    //             needs, create SAP solutions`;
    //             break;
        
    //         case "contact-us":
    //             document.title = `Hire Dedicated Full Time
    //             Remote Developers -
    //             Code1 Dev
    //             `;

    //             document.querySelectorAll('[property="og:title"]')[0].content = `Hire Dedicated Full Time
    //             Remote Developers -
    //             Code1 Dev
    //             `;

    //             document.querySelectorAll('[property="og:description"]')[0].content = `We Hire dedicated remote
    //             developers across 50+ skills in 7
    //             days for the Clients. Join 100+
    //             companies that developers from
    //             Code1 Dev with a simple Hire
    //             Process.
    //             `;
    //             document.querySelectorAll('meta[name="description"]')[0].content = `We Hire dedicated remote
    //             developers across 50+ skills in 7
    //             days for the Clients. Join 100+
    //             companies that developers from
    //             Code1 Dev with a simple Hire
    //             Process.
    //             `;
    //             break;
        
    //         case "about-us":
    //             document.title = `About Us - Code1 Dev`;

    //             document.querySelectorAll('[property="og:title"]')[0].content = `About Us - Code1 Dev`;

    //             document.querySelectorAll('[property="og:description"]')[0].content = `Our vision of taking staff to next level
    //             by adapting remote cultures
    //             worldwide and opening global
    //             equality staff for IT Projects
    //             `;
    //             document.querySelectorAll('meta[name="description"]')[0].content = `Our vision of taking staff to next level
    //             by adapting remote cultures
    //             worldwide and opening global
    //             equality staff for IT Projects
    //             `;
    //             break;
        
    //         default:
    //             // document.title = `Hire the World's Most
    //             // Vetted Remote Developers
    //             // - Code1 Dev
    //             // `;

    //             // document.querySelectorAll('[property="og:title"]')[0].content = `Hire the World's Most
    //             // Vetted Remote Developers
    //             // - Code1 Dev
    //             // `;

    //             // document.querySelectorAll('[property="og:description"]')[0].content = `Code1 Dev is the IT staff hiring Cloud
    //             // solution platform for companies to
    //             // hire the most vetted remote
    //             // developers & software engineers on
    //             // full-time jobs basis`;
               
    //             // document.querySelectorAll('meta[name="description"]')[0].content = `Code1 Dev is the IT staff hiring Cloud
    //             // solution platform for companies to
    //             // hire the most vetted remote
    //             // developers & software engineers on
    //             // full-time jobs basis`;
    //             // break;
    //     }
        
    // }, [pageRoute]);


    return true;
};
export default PageTitle;