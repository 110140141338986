import React, { useState, useEffect } from 'react';
import { Link, withRouter } from "react-router-dom";
import CallingMethod from '../../../Services/CallingMethod';

//import useLoader from "../Services/useLoader";
export const Dashboard = (props) => {
    //const [loader, showLoader, hideLoader] = useLoader();
    var [sessadminname, setSessadminname] = useState('');
    var [sessAdminProfile, setSessAdminProfile] = useState('assets/images/users/avatar-1.jpg');

    var [countBlog, setCountBlog] = useState(0);
    var [countPillerOfStrength, setCountPillerOfStrength] = useState(0);
    var [countHireRequest, setCountHireRequest] = useState(0);

    useEffect(() => {
        if (localStorage.getItem('admin')) {
            var sessData = localStorage.getItem('admin');
            sessData = JSON.parse(sessData);
            var sFullname = sessData.firstname + ' ' + sessData.lastname;
            setSessadminname(sFullname);
            if(sessData.profile){
                var adminProfile = JSON.parse(localStorage.getItem('profilePath')).concat(sessData.profile);
                setSessAdminProfile(adminProfile);
            }
            getAdminDashboard();
        }
    }, []);

    const getAdminDashboard = async () => {
        const response = await CallingMethod.webGetData(`api/admin/dashboard`);

        if(response.data.status == 1){
            setCountBlog(response.data.data.blog);
            setCountPillerOfStrength(response.data.data.piller);
            setCountHireRequest(response.data.data.hire);
        }
    };

    /* Rollback Info End */
    return (
        <>
            <section className="main-content userlist_main_sec">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="row">
                                    <div className="col-md-4">
                                        <Link to="/admin/blog">

                                            <div className="card mini-stats-wid">
                                                <div className="card-body">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <p className="text-muted fw-medium">Blogs</p>
                                                            <h4 className="mb-0">{countBlog}</h4>
                                                        </div>

                                                        <div className="flex-shrink-0 align-self-center">
                                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                                                <span className="avatar-title">
                                                                    <i className="bx bx-detail font-size-24"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-4">
                                        <Link to="/admin/piller-of-strength">
                                            <div className="card mini-stats-wid">
                                                <div className="card-body">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <p className="text-muted fw-medium">Pillar Of Strength</p>
                                                            <h4 className="mb-0">{countPillerOfStrength}</h4>
                                                        </div>

                                                        <div className="flex-shrink-0 align-self-center">
                                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                                                <span className="avatar-title">
                                                                    <i className="bx bx-shape-circle font-size-24"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    
                                    <div className="col-md-4">
                                    <Link to="/admin/hire-request">
                                        <div className="card mini-stats-wid">
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium">Hire Request</p>
                                                        <h4 className="mb-0">{countHireRequest}</h4>
                                                    </div>

                                                    <div className="flex-shrink-0 align-self-center">
                                                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                                            <span className="avatar-title rounded-circle bg-primary">
                                                                <i className="bx bx-mail-send font-size-24"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default withRouter(Dashboard);