import { memo, useState, useEffect } from "react";
import { Button, Form} from 'react-bootstrap';
import { capitalizeFirst } from '../Services/Custom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

const SelectSearch = ({inputName, register, inputValue, CallingMethod, apiName, setValue = false}) => {

    const [inputSearchValue, setInputSearchValue] = useState('');
    const [chips, setChips] = useState(inputValue);
    const [optionsDef, setOptionsDef] = useState([]);

    useEffect(() => {
      if(inputValue.length > 0){
        if(inputValue != chips){
          setChips(inputValue);
          setValue(inputName, JSON.stringify(inputValue));
          console.log(inputValue);
        }
        
      }
    }, [inputValue]);
    
    
    const loadOptions = async (inputSearchValue ="", callback) => {
      // console.log('on load options function '+ inputSearchValue)
      var sendd = { page: 1, size: 10, search: inputSearchValue, status: 1 };
      const response = await CallingMethod.get_search_list('search-list/'+apiName, sendd);
      // console.log(response.data);
      const options = [];
      
      if(response.data.status == 1){
        response.data.data.forEach((item) => {
          options.push({
            label: item.label,
            value: item.value
          })
        });
      }
      callback(options);
      setOptionsDef(options);
    }
  // const handleInputChange = (newValue) => {
  //   const inputSearchValue = newValue.replace(/\W/g, '');
  //   setInputSearchValue(inputSearchValue);
  //   return inputSearchValue;
  // };
  const handleSelected = (selectedChips) => {
    console.log(selectedChips);
    setChips(selectedChips);
    setValue(inputName, JSON.stringify(selectedChips));
  };
  return (
    <>
        {/* <Select 
            // value={inputValue}
            defaultValue={inputValue}
            placeholder="Search here..."
            onChange={setChips}
            options={options} 
            isMulti={true} 
        /> */}
        <AsyncSelect
            isMulti={true}
            placeholder={`Search ${capitalizeFirst(inputName)} here...`}
            cacheOptions
            defaultOptions
            value={chips}
            loadOptions={loadOptions}
            onChange={handleSelected}
            // onInputChange={handleInputChange}
        />
        <input type="hidden" name={inputName} className="form-control" {...register(inputName, {required: true })} />
        {/* {JSON.stringify(chips)} */}
    </>
  );
};

export default memo(SelectSearch);