import React, { useEffect, useState, lazy, Suspense } from 'react';
import { withRouter, useHistory, Route, Switch } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Loader from './Loader';
import Header from './Header';
import Footer from './Footer';
import PageTitle from '../../../Components/PageTitle';
import { PageMetaData } from '../../../PageMetaData';
import ErrorBoundary from '../../../Components/ErrorBoundary';

// Lazy-loaded component imports
const Home = lazy(() => import('../Home'));
const Usecase = lazy(() => import('../Usecase'));
const UseCaseDetail = lazy(() => import('../UseCaseDetail'));
const AboutUs = lazy(() => import('../AboutUs'));
const Blogs = lazy(() => import('../Blogs'));
const BlogDetail = lazy(() => import('../BlogDetail'));
const ContactUs = lazy(() => import('../ContactUs'));
const HireDeveloper = lazy(() => import('../HireDeveloper'));
const HireTechStepOne = lazy(() => import('../HireTechStepOne'));
const HireTechStepTwo = lazy(() => import('../HireTechStepTwo'));
const HireTechStepThree = lazy(() => import('../HireTechStepThree'));
const StepFinish = lazy(() => import('../StepFinish'));
const ResumeBuilder = lazy(() => import('../ResumeBuilder'));
const ResumeBuilderPersonal = lazy(() => import('../ResumeBuilderPersonal'));
const ResumeBuilderExperience = lazy(() => import('../ResumeBuilderExperience'));
const ResumeBuilderTemplate = lazy(() => import('../ResumeBuilderTemplate'));
const ResumeBuilderCompleted = lazy(() => import('../ResumeBuilderCompleted'));
const PrivacyPolicy = lazy(() => import('../PrivacyPolicy'));
const TermsCondition = lazy(() => import('../TermsCondition'));
const HowToHireAFullStackDeveloper = lazy(() => import('../HowToHireAFullStackDeveloper'));
const HowToHireDataEngineers = lazy(() => import('../HowToHireDataEngineers'));
const HowToHireADevopsEngineer = lazy(() => import('../HowToHireADevopsEngineer'));
const HowToHireAnAngularDeveloper = lazy(() => import('../HowToHireAnAngularDeveloper'));
const SapConsultant = lazy(() => import('../SapConsultant'));
const DotNet = lazy(() => import('../DotNet'));
const Sitemap = lazy(() => import('../Sitemap'));

function WebsiteLayout(props) {
  const history = useHistory();
  const [isShowLoader, setIsShowLoader] = useState(0);

  const hideShowLoader = async (isshow = 0) => {
    setIsShowLoader(isshow);
  }

  return (
    <ErrorBoundary>
      {((window.location.href.indexOf("step-one") < 0) && (window.location.href.indexOf("step-two") < 0) && (window.location.href.indexOf("step-three") < 0) && (window.location.href.indexOf("step-completed") < 0)) ? (
        <Header />
      ) : null}
      <>
        <Loader stateLoader={isShowLoader} />
        <PageTitle />
        <Helmet>
          <title>{PageMetaData.home.title}</title>
          <meta name="description" content={PageMetaData.home.description} />
          <meta name="keywords" content="Hiring, Providing" />
        </Helmet>
        <Suspense fallback={<Loader stateLoader={isShowLoader} />}>
          <Switch>
            <Route path="/" exact component={Home} pageLoader={hideShowLoader} />
            <Route path="/usecase" exact component={Usecase} pageLoader={hideShowLoader} />
            <Route path="/useCase-detail/:id" exact component={UseCaseDetail} pageLoader={hideShowLoader} />
            <Route path="/about-us" exact component={AboutUs} pageLoader={hideShowLoader} />
            <Route path="/blogs" exact component={Blogs} pageLoader={hideShowLoader} />
            <Route path="/blog-detail/:id" exact component={BlogDetail} pageLoader={hideShowLoader} />
            <Route path="/contact-us" exact component={ContactUs} pageLoader={hideShowLoader} />
            <Route path="/hire-developer" exact component={HireDeveloper} pageLoader={hideShowLoader} />
            <Route path="/hire-tech/step-one" exact component={HireTechStepOne} pageLoader={hideShowLoader} />
            <Route path="/hire-tech/step-two" exact component={HireTechStepTwo} pageLoader={hideShowLoader} />
            <Route path="/hire-tech/step-three" exact component={HireTechStepThree} pageLoader={hideShowLoader} />
            <Route path="/hire-tech/step-completed" exact component={StepFinish} pageLoader={hideShowLoader} />
            <Route path="/resume-builder" exact component={ResumeBuilder} pageLoader={hideShowLoader} />
            <Route path="/resume-builder/personal" exact component={ResumeBuilderPersonal} pageLoader={hideShowLoader} />
            <Route path="/resume-builder/experience" exact component={ResumeBuilderExperience} pageLoader={hideShowLoader} />
            <Route path="/resume-builder/template" exact component={ResumeBuilderTemplate} pageLoader={hideShowLoader} />
            <Route path="/resume-builder/completed" exact component={ResumeBuilderCompleted} pageLoader={hideShowLoader} />
            <Route path="/privacy-policy" exact component={PrivacyPolicy} pageLoader={hideShowLoader} />
            <Route path="/terms-conditions" exact component={TermsCondition} pageLoader={hideShowLoader} />
            <Route path="/how-to-hire-a-full-stack-developer" exact component={HowToHireAFullStackDeveloper} pageLoader={hideShowLoader} />
            <Route path="/how-to-hire-data-engineers" exact component={HowToHireDataEngineers} pageLoader={hideShowLoader} />
            <Route path="/how-to-hire-a-devops-engineer" exact component={HowToHireADevopsEngineer} pageLoader={hideShowLoader} />
            <Route path="/how-to-hire-an-angular-developer" exact component={HowToHireAnAngularDeveloper} pageLoader={hideShowLoader} />
            <Route path="/sap-consultant" exact component={SapConsultant} pageLoader={hideShowLoader} />
            <Route path="/dot-net" exact component={DotNet} pageLoader={hideShowLoader} />
            {/* <Route path="/generate-sitemap" exact component={Sitemap} pageLoader={hideShowLoader} /> */}
            <Route path="/" component={Home} />
          </Switch>
        </Suspense>
      </>
      {((window.location.href.indexOf("step-one") < 0) && (window.location.href.indexOf("step-two") < 0) && (window.location.href.indexOf("step-three") < 0) && (window.location.href.indexOf("step-completed") < 0)) ? (
        <Footer />
      ) : null}
    </ErrorBoundary>
  );
}

export default withRouter(WebsiteLayout);
