import React, { useEffect, useState } from 'react';
import { Link, withRouter, useHistory } from "react-router-dom";
const REACT_APP_NAME = process.env.REACT_APP_NAME;

export const Footer = (props) => {

  
    return (
        <>
            
            <footer className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                        Copyright @ {(new Date().getFullYear())}    ITVeT  Technologies Pvt. Ltd.
                        </div>
                        <div className="col-sm-6">
                            <div className="text-sm-end d-none d-sm-block">
                                {/* Design & Develop by Themesbrand */}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            {/* <Modal show={showAddMoney} onHide={handleCloseAddMoney}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Money To Users Wallet</Modal.Title>
                </Modal.Header>
                <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmitAddMoney)}>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-lg-12">
                                <Form.Group className="mb-3" controlId="formTitle">
                                    <Form.Label>Mobile No</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Mobile No" className="form-control" {...register("mobile_no", {
                                        required: true, maxLength: 10, pattern: {
                                            value: /^[0-9]*$/
                                        },
                                    })} />
                                    <div className="error">
                                        {errors.mobile_no?.type === 'required' && "Mobile No is required"}
                                        {errors.mobile_no?.type === 'pattern' && "Only digits allowed"}
                                        {errors.mobile_no?.type === 'maxLength' && "Maximum 10 digits allowed"}
                                    </div>
                                </Form.Group>
                            </div>

                            <div className="col-lg-12">
                                <Form.Group className="mb-3" controlId="formTitle">
                                    <Form.Label>Amount</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Amount" className="form-control" {...register("amount", {
                                        required: true, maxLength: 10, pattern: {
                                            value: /^[0-9]*$/
                                        },
                                    })} />
                                    <div className="error">
                                        {errors.amount?.type === 'required' && "Amount is required"}
                                        {errors.amount?.type === 'pattern' && "Only digits allowed"}
                                        {errors.amount?.type === 'maxLength' && "Maximum 10 digits allowed"}
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-lg-12">
                                <Form.Group className="mb-3" controlId="formTitle">
                                    <Form.Label>Show Transaction To User</Form.Label>
                                    <div className="col-lg-12">
                                        <Form.Check inline label="Show To User" ref={register} value="1" id="active-status" type="radio" {...register("show_not_show")} />
                                        <Form.Check inline label="Not Show To User" ref={register} id="inactive-status" type="radio" value="0" {...register("show_not_show")} />
                                        <div className="error">
                                            {errors.show_not_show?.type === 'required' && "Show To User is required"}
                                        </div>
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseAddMoney}>Close</Button>
                        <Button variant="primary" type="submit">Save</Button>
                    </Modal.Footer>
                </Form>
            </Modal> */}
        </>
    )
}

export default withRouter(Footer);