import React, { useEffect, useState } from 'react';
import { Link, withRouter, useHistory } from "react-router-dom";

export const Sidebar = (props) => {
 
    return (
        <>
           
            {/* Left Sidebar Start */}
            <div className="vertical-menu">
                <div data-simplebar className="h-100">
                    {/* Sidemenu  */}
                    <div id="sidebar-menu">
                        {/* Left Menu Start */}
                        <ul className="metismenu list-unstyled" id="side-menu">
                            <li className="menu-title" key="t-menu">Menu</li>
                            <li>
                                <Link to="/admin/dashboard" className="waves-effect">
                                    <i className="bx bx-home-circle"></i>
                                    <span key="t-dashboards">Dashboard</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/admin/hire-request" className="waves-effect">
                                    <i className='bx bx-mail-send'></i>
                                    <span key="t-calendar">Hire Request</span>
                                </Link>
                            </li>

                            {/* <li>
                                <Link to="/admin/user" className="waves-effect">
                                    <i className='bx bxs-user-detail'></i>
                                    <span key="t-calendar">Users</span>
                                </Link>
                            </li> */}
                            <li>
                                <Link to="/admin/technology" className="waves-effect">
                                    <i className='bx bx-code-alt'></i>
                                    <span key="t-calendar">Technology</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/admin/client" className="waves-effect">
                                    <i className='bx bx-user-voice'></i>
                                    <span key="t-calendar">Clients</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/admin/client-story" className="waves-effect">
                                    <i className='bx bx-comment-dots'></i>
                                    <span key="t-calendar">Client Story</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/admin/blog" className="waves-effect">
                                    <i className='bx bx-detail'></i>
                                    <span key="t-calendar">Blogs</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/admin/faq" className="waves-effect">
                                    <i className='bx bx-question-mark'></i>
                                    <span key="t-calendar">FAQs</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/admin/usecase" className="waves-effect">
                                    <i className='bx bxs-comment-detail'></i>
                                    <span key="t-calendar">UseCase</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/admin/founder" className="waves-effect">
                                    <i className='bx bx-shield-quarter'></i>
                                    <span key="t-calendar">Founder</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/admin/journey" className="waves-effect">
                                    <i className='bx bx-stats'></i>
                                    <span key="t-calendar">Journey</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/admin/piller-of-strength" className="waves-effect">
                                    <i className='bx bx-shape-circle'></i>
                                    <span key="t-calendar">Piller Of Strength</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    {/* Sidebar  */}
                </div>
            </div>
            {/* Left Sidebar End */}

        </>
    )
}

export default withRouter(Sidebar);