import React, { useState, useEffect } from 'react';

function ErrorBoundary({ children }) {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    return () => {
      setHasError(false); // Reset error state when component unmounts
    };
  }, []);



  if (hasError) {
    // You can customize the error message displayed to the user
    return <h1>Something went wrong.</h1>;
  }

  return children;
}

export default ErrorBoundary;
