import React, { useEffect, useState, useMemo } from 'react';
import { Link, withRouter } from "react-router-dom";
import { Dropdown, Modal, Button } from "react-bootstrap";
import Alert from '../../../Services/Alert';
//import {amountFormat} from '../../../Services/Custom';
import DataTable from 'react-data-table-component';
import CallingMethod from '../../../Services/CallingMethod';
import { capitalizeFirst } from '../../../Services/Custom';
import { exportExcel } from '../Include/Export';
const BaseRouteName = "technology";
const Cusrrency = process.env.REACT_APP_CURRENCY_SYMBOL;

export const List = (props) => {

	const [show, setShow] = useState(false);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [search, setSearch] = useState("");
	const [status, setStatus] = useState("");
	const [isReset, setIsReset] = useState(0);
	const [iconPath, setIconPath] = useState("");
	const columns = useMemo(() => [
		{
			name: 'Name',
			selector: row => {
				return row.name
			},
		},

		{
			name: 'Icon',
			selector: row => {
				var icon = '';
				if (typeof row.icon != undefined && row.icon != ''){
					icon = <img src={iconPath + row.icon} alt='icon' width='50px' height="50px" className='img-thumbnail' />;
				}
				return <div>{icon}</div>
			}
		},
		
		{
			name: 'Status',
			selector: row => {
				if (row.status){
					return <button type="button" className="btn btn-success mr-4 btn-sm waves-effect waves-light" onClick={() => onUpdateSt(row._id, '0')}>Active</button>
				} else {
					return <button type="button" className="btn btn-danger mr-4 btn-sm waves-effect waves-light" onClick={() => onUpdateSt(row._id, 1)}>In Active</button>
				}
			},
		},
		{
			name: 'Action',
			selector: (row, ind) => {
				return <><Dropdown id={`drop_main_${ind}`} key={ind} rendermenuonmount={true.toString()}>
					<Dropdown.Toggle id={`drop_${ind}`} variant="secondary" ><i className='bx bx-list-ul' ></i></Dropdown.Toggle>

					<Dropdown.Menu>
						<Dropdown.Item as={Link} to={{ pathname: `/admin/${BaseRouteName}/edit/${row._id}` }}><i className='bx bxs-edit' ></i>&nbsp;Edit</Dropdown.Item>
						<Dropdown.Item href="#" onClick={() => onConfirm(row._id)}><i className='bx bxs-trash' ></i>&nbsp;Delete</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown></>
			},
		},
	]
	);

	const handleClose = () => setShow(false);
	
	const handleChangeSearch = (e) => {
		setSearch(e.target.value);
	};
	const handleChangeStatus = (e) => {
		setStatus(e.target.value);
	};
	const handleFilter = () => {
		//userData(1);
		setIsReset(!isReset);
	};
	function handleResetFilter() {
		setSearch(""); setStatus("");
		//userData(1);
		setIsReset(0);
	}

	const userData = async page => {
		setLoading(true);
		var sendd = { page: page, size: perPage, search: search, status: status };
		const response = await CallingMethod.list(BaseRouteName, sendd);
		setData(response.data.data.docs);
		setTotalRows(response.data.total);
		setLoading(false);
		setIconPath(response.data.path);
	};
	const handlePageChange = page => {
		userData(page);
	};
	const handlePerRowsChange = async (newPerPage, page) => {
		setLoading(true);
		var sendd = { page: page, size: perPage, search: '' };
		const response = await CallingMethod.list(BaseRouteName, sendd)
		setData(response.data.data.docs);
		setPerPage(newPerPage);
		setLoading(false);
	};
	
	useEffect(() => {
		userData(1);
	}, [isReset]);

	function onConfirm(id) {
		Alert.warning().then((result) => {
			if (result.isConfirmed) {
				CallingMethod.delete(BaseRouteName, id).then((res) => res.data).then(res => {
					if (res.status) {
						Alert.success(res.message);
						userData();
					} else {
						Alert.error(res.message)
					}
				}).catch(error => {
					Alert.error(error.message)
				})

			}
		});
	}
	
	const onUpdateSt = (id, stto,is_widthraw=0) => {
		Alert.warning().then((result) => {
			if (result.isConfirmed) {
				CallingMethod.update_status(BaseRouteName, { id: id, status: stto,is_widthraw:is_widthraw }).then((res) => res.data).then(res => {
					if (res.status) {
						Alert.success(res.message);
						userData();
					} else {
						Alert.error(res.message)
					}
				}).catch(error => {
					Alert.error(error.message)
				})
			}
		});
	}
	const exportToExcel = () => {
		var sendd = { search: search, status: status };
		CallingMethod.export(BaseRouteName, sendd).then((res) => res.data).then(res => {
			if (res.status) {
				// console.log(res.data);
				exportExcel(res.data, capitalizeFirst(BaseRouteName)+ ' Export');
			} else {
				Alert.error(res.message)
			}
		}).catch(error => {
			Alert.error(error.message)
		})
	}
	
	return (
		<>
			<section className="main-content userlist_main_sec">
				<div className="page-content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-12">
								<div className="card">
									<div className="card-body">
										<div className="header-text">
											<h4 className="card-title mb-4">{capitalizeFirst(BaseRouteName)}</h4>
											<div className="edit-btn">
												<Link to={`/admin/${BaseRouteName}/add`} className="btn btn-outline-primary btn-md btn-rounded waves-effect waves-light mr-5">
													<i className='bx bx-add-to-queue'></i>
													Add
												</Link>
												<Button onClick={() => exportToExcel()} className="btn btn-success mr-4 btn-md btn-rounded waves-effect waves-light " style={{backgroundColor:'none'}}>Export</Button>
												
											</div>
										</div>
										
										<div className="row">
											<div className="col-lg-3 col-md-3">
												<input type="text" className="form-control" value={search} onChange={(e) => handleChangeSearch(e)} />
											</div>
											<div className="col-lg-3 col-md-3">
												<select value={status} className="form-control" onChange={(e) => handleChangeStatus(e)}>
													<option value="">Both</option>
													<option value="1">Active</option>
													<option value="0">DeActive</option>
												</select>
											</div>
											<div className="col-lg-3 col-md-3">
												<button type="button" className="btn btn-outline-primary mr-4 btn-md btn-rounded waves-effect waves-light" onClick={() => handleFilter()}>Filter</button>&nbsp;
												<button type="button" className="btn btn-outline-secondary mr-4 btn-md btn-rounded waves-effect waves-light" onClick={() =>handleResetFilter()}>Reset</button>
											</div>
										</div>
										<div className="table-responsive">
											<DataTable
												columns={columns}
												data={data}
												progressPending={loading}
												pagination
												paginationServer
												paginationTotalRows={totalRows}
												onChangeRowsPerPage={handlePerRowsChange}
												onChangePage={handlePageChange}
											/>
										</div>
										{/* end table-responsive  */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			
		</>
	)
}

export default withRouter(List);