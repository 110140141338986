import React, { useEffect, useState } from 'react';
import { Link, withRouter, useHistory } from "react-router-dom";
const REACT_APP_NAME = process.env.REACT_APP_NAME;

export const Footer = (props) => {

    const topMeUp = () => {
        window.scrollTo(0, 0);
    };
    return (
        <>
            <footer>
                <img src='/web/assets/images/scroll.png' onClick={topMeUp} className="scrollup" width="70px" alt='scroll up'></img>
                
    

                <div className="row">
                    <div className="col-md-3">
                        <h5>About<span> CODE 1</span></h5>
                        <p >We are a staff hiring solution providers that make the employing system 10X quicker and more effective for organizations. </p>

                        <h5 className="mt-5">Contact<span> info</span></h5>
                        <p><a href="tel:+91-95875 57333">+91-95875 57333</a></p>
                        <p><a href="mailto:info@code1.dev">info@code1.dev</a></p>
                    </div>
                    <div className="col-md-3 text-left">
                        <h5 >Important <span> Links</span></h5>
                        <ul className="p-0">
                            <li><Link to="/" onClick={window.scrollTo(0, 0)} className="whitecolor">HOME</Link></li>
                            <li><Link to="/about-us" className="whitecolor">ABOUT US</Link></li>
                            <li><Link to="/contact-us"className="whitecolor">CONTACT US</Link></li>
                            <li><Link to="/usecase" className="whitecolor">USE CASE</Link></li>
                            <li><Link to="/blogs" className="whitecolor">BLOGS</Link></li>
                            <li><Link to="/resume-builder" className="whitecolor">RESUME BUILDER</Link></li>
                            <li><Link to="/privacy-policy" className="whitecolor">PRIVACY POLICY</Link></li>
                            <li><Link to="/terms-conditions" className="whitecolor">TERMS & CONDITIONS</Link></li>
                            
                        </ul>
                    </div>
                    <div className="col-md-4 text-left">
                        <h5 >  Hire  <span>Technologies</span></h5>
                        <ul className="p-0">
                            <li><Link to="/how-to-hire-a-full-stack-developer" className="whitecolor text-uppercase">Full-Stack Developer</Link></li>
                            <li><Link to="/how-to-hire-data-engineers" className="whitecolor text-uppercase">  Data Engineer</Link></li>
                            <li><Link to="/how-to-hire-a-devops-engineer" className="whitecolor text-uppercase">  Devops Developer</Link></li>
                            <li><Link to="/how-to-hire-an-angular-developer" className="whitecolor text-uppercase">  Angular Developer</Link></li>
                            <li><Link to="/sap-consultant" className="whitecolor text-uppercase"> SAP Consultant</Link></li>
                            <li><Link to="/dot-net" className="whitecolor text-uppercase"> Dot Net Developer</Link></li>
                            
                        </ul>
                    </div>
                    <div className="col-md-2 text-left" >
                        <h5 >Follow  <span> us</span></h5>
                        <div className='row'>
                        <div className="col-md-12" >
                        {/* <img src='/web/assets/images/insta.png' className='mr-2' width={'39px'}></img> */}
                            {/* <img src='/web/assets/images/fb.png' className='mr-2' width={'45px'}></img> */}
                            {/* <img src='/web/assets/images/twiter.png' width={'40px'}></img> */}
                            <a href='https://www.linkedin.com/company/thecode1/'> <img src='/web/assets/images/linkedin-icon-light.png' width={'40px'} alt="linkedin"/></a>
                        </div>


                        </div>
                        {/* <p>Send your email to us for daily updates</p>
                        <div className="custom-search2">
                            <input type="text" className="custom-search-input2" placeholder="Tell us your email ID"/>
                                <button className="custom-search-botton2 whitecolor" >Send</button>
                        </div>
                         */}
                    </div>
                </div>
            </footer>

            <section className="container-fluid">
                <div className="row ">
                    <div className="col-md-12 copyright" align="center">
                        {/* <p>Copyright {(new Date().getFullYear())} <b>{REACT_APP_NAME}</b> </p> */}
                        <p>Copyright @ {(new Date().getFullYear())} <b>&nbsp;ITVeT &nbsp;Technologies Pvt. Ltd.</b> </p>
                    </div>
                </div>
            </section>

        </>
    )
}

export default withRouter(Footer);