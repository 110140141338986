import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';

import Alert from '../../../Services/Alert';
import CallingMethod from '../../../Services/CallingMethod';
import { capitalizeFirst } from '../../../Services/Custom';
const BaseRouteName = "user";



export const Add = (props) => {

  var currentRoutePath = props.match.path.split('/');
  let currentRouteName = currentRoutePath[currentRoutePath.length - 1];

  const history = useHistory();
  var [images, setImages] = useState([]);

  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  const onSubmit = async (data) => {
    const formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }
    if (images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        const element = images[i];
        formData.append(`${element['inputName']}`, element);
      }
    }

    CallingMethod.save(BaseRouteName, formData).then((res) => res.data).then(res => {
      if (res.status) {
        Alert.success(res.message)
        history.push('/admin/' + BaseRouteName);
      } else {
        Alert.error(res.message)
      }
    }).catch(error => {
      Alert.error(error.message)
    })
  }

  const handleFileSelect = (event) => {
    let newFile = event.target.files[0];
    newFile.inputName = event.target.name;
    setImages([...images, newFile]);
  }
  return (
    <>
      <section className="main-content slideradd_main_sec">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">{capitalizeFirst(BaseRouteName)} Add</h4>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-lg-6">
                          <Form.Group className="mb-3" controlId="fullname">
                            <Form.Label>{capitalizeFirst('fullname')}</Form.Label>
                            <Form.Control type="text" placeholder={`Enter ${capitalizeFirst('fullname')}`} className="form-control" {...register("fullname", { required: true, maxLength: 200 })} />
                            <div className="error">
                              {errors.fullname?.type === 'required' && capitalizeFirst('fullname') + " is required"}
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group controlId="profile" className="mb-3">
                            <Form.Label>{capitalizeFirst('profile')}</Form.Label>
                            <input type="file" className="form-control" name="profile" onChange={handleFileSelect} />
                            {errors.profile && errors.profile.type === "required" && <div className="error">{capitalizeFirst('profile')} is required.</div>}
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group className="mb-3" controlId="mobile_no">
                            <Form.Label>{capitalizeFirst('mobile_no')}</Form.Label>
                            <div className="row">
                              <div className="col-lg-3">
                                <Form.Control type="number" pattern="^-?[0-9]\d*\.?\d*$" placeholder="91" className="form-control" {...register("country_code", { required: true, valueAsNumber: true, maxLength: 80 })} />
                                <div className="error">
                                  {errors.country_code?.type === 'required' && capitalizeFirst('country_code') + " is required"}
                                </div>
                              </div>
                              <div className="col-lg-9">
                                <Form.Control type="text" placeholder={`Enter ${capitalizeFirst('mobile_no')}`} className="form-control" {...register("mobile_no", { required: true, maxLength: 80 })} />
                                <div className="error">
                                  {errors.mobile_no?.type === 'required' && capitalizeFirst('mobile_no') + " is required"}
                                </div>
                              </div>
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group className="mb-3" controlId="email">
                            <Form.Label>{capitalizeFirst('email')}</Form.Label>
                            <Form.Control type="email" placeholder={`Enter ${capitalizeFirst('email')}`} className="form-control" {...register("email", { required: true, email: true, maxLength: 200 })} />
                            <div className="error">
                              {errors.email?.type === 'required' && capitalizeFirst('email') + " is required"}
                            </div>
                          </Form.Group>
                        </div>

                      </div>
                      <div className="btn-submit">
                        <Button className="btn btn-primary w-md" type="submit">Submit</Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default withRouter(Add);