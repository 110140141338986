import React, { useEffect, useState } from 'react';
import { Link, withRouter, useHistory } from "react-router-dom";
import logos from '../../../images/logo.svg';
// import site_logo from '../../';
import Api from '../../../Services/Api';
import Alert from '../../../Services/Alert';
import logoDark from '../../../images/logo-dark.png';
import avtar from '../../../images/avatar-1.jpg';

import { useForm } from 'react-hook-form';
import { Form, FormLabel, Button, Modal } from 'react-bootstrap';
import Footer from './Footer';
import Sidebar from './Sidebar';
const REACT_APP_NAME = process.env.REACT_APP_NAME;

export const Header = (props) => {
    var history = useHistory();
    var [sessadminname, setSessadminname] = useState('');
    var [sessAdminProfile, setSessAdminProfile] = useState('/assets/images/users/avatar-1.jpg');

    const doLogout = () => {
        Api.post('logout').then((res) => res.data).then(res => {
            if (res.status) {
                localStorage.clear();
                history.push("/admin/login");
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        })
    }

    useEffect(() => {
        if (localStorage.getItem('admin')) {
            var sessData = localStorage.getItem('admin');
            sessData = JSON.parse(sessData);
            var sFullname = sessData.firstname + ' ' + sessData.lastname;
            setSessadminname(sFullname);
            if (sessData.profile) {
                var adminProfile = JSON.parse(localStorage.getItem('profilePath')).concat(sessData.profile);
                setSessAdminProfile(adminProfile);
            }
        }
    }, []);
    return (
        <>
            <div id="layout-wrapper">
                <header id="page-topbar">
                    <div className="navbar-header">
                        <div className="d-flex">
                            <div className="navbar-brand-box">

                                <Link to="/admin/dashboard" className="logo logo-light">
                                    {/* <h1 className="mt-4">LOGO</h1> */}
                                    <span className="logo-sm">
                                        <img src={'/web/assets/images/logo.png'} alt="" width="80px" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={'/web/assets/images/logo.png'} alt="" width="130px" />
                                    </span>
                                </Link>
                            </div>

                            <button type="button" className="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn">
                                <i className="fa fa-fw fa-bars"></i>
                            </button>
                        </div>

                        <div className="d-flex">
                            <div className="dropdown d-inline-block d-lg-none ms-2">
                                <button type="button" className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="mdi mdi-magnify"></i>
                                </button>
                                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                                    aria-labelledby="page-header-search-dropdown">

                                    <form className="p-3">
                                        <div className="form-group m-0">
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary" type="submit"><i className="mdi mdi-magnify"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            {/* <div className="dropdown d-inline-block">
                                <button type="button" className="btn header-item noti-icon waves-effect" id="page-header-notifications-dropdown">
                                    <i className="bx bx-cart bx-tada"></i>
                                    <span className="badge bg-danger rounded-pill">1</span>
                                </button>
                            </div>
                             */}

                            {/* <div className="dropdown d-inline-block">
                                <button type="button" className="btn header-item noti-icon waves-effect" id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="bx bx-bell bx-tada"></i>
                                    <span className="badge bg-danger rounded-pill">3</span>
                                </button>
                            </div>
                             */}
                            <div className="dropdown d-inline-block">
                                <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img className="rounded-circle header-profile-user" src={sessAdminProfile}
                                        alt="Header Avatar" />
                                    <span className="d-none d-xl-inline-block ms-1" key="t-henry">{sessadminname}</span>
                                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                                </button>
                                <div className="dropdown-menu dropdown-menu-end">

                                    <Link to="/admin/profile-update" className="dropdown-item">
                                        <i className="bx bx-user font-size-16 align-middle me-1"></i> <span key="t-profile">Profile</span>
                                    </Link>
                                    <Link to="/admin/change-password" className="dropdown-item">
                                        <i className="bx bx-lock font-size-16 align-middle me-1"></i> <span key="t-profile">Change Password</span>
                                    </Link>
                                    <div className="dropdown-divider"></div>
                                    <button className="dropdown-item text-danger" type="button" onClick={() => doLogout()}><i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i> <span key="t-logout">Logout</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <Sidebar />
            </div>
            <link
                rel="stylesheet"
                href="https://unpkg.com/bootstrap-icons@1.10.2/font/bootstrap-icons.css"
            />
            <Footer />
        </>
    )
}

export default withRouter(Header);