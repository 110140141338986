import React, { useEffect, useState } from 'react';
import { Link, withRouter, useHistory } from "react-router-dom";
import logos from '../../../images/logo.svg';
import site_logo from '../../../images/idealgamez-final-logo-512x512.png';
import Api from '../../../Services/Api';
import Alert from '../../../Services/Alert';
import logoDark from '../../../images/logo-dark.png';
import avtar from '../../../images/avatar-1.jpg';

import { useForm } from 'react-hook-form';
import { Form, FormLabel, Button, Modal } from 'react-bootstrap';
import Footer from './Footer';
// import Sidebar from './Sidebar';
const REACT_APP_NAME = process.env.REACT_APP_NAME;

export const Header = (props) => {
    var history = useHistory();
    
    return (
        <>
            
            <header>
                <nav className="navbar navbar-expand-lg navbar-light">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <Link to="/" className="navbar-brand"><img src="/web/assets/images/logo.png" width="160px" alt="logo"/></Link>
                    <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
                    <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                        <li className="nav-item active">
                            <Link to="/" className="nav-link">Home <span className="sr-only">(current)</span></Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/usecase" className="nav-link">Use Cases</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/about-us" className="nav-link">About us</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/blogs" className="nav-link">Blogs</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/contact-us" className="nav-link">Contact us</Link>
                        </li>
                      
                    </ul>

                    <form className="form-inline my-2 my-lg-0">
                        <Link to="/resume-builder" className="btn headbtn top-small">RESUME BUILDER</Link>
                        {(((window.location.href.indexOf("hire-developer") < 0))?(
                        <Link to="/hire-developer" className="btn headbtn2 top-small">HIRE DEVELOPER</Link>
                        ):null)}
                    </form>
                    </div>
                </nav>
            </header>
        </>
    )
}

export default withRouter(Header);