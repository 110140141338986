import React, { useRef, useEffect, useState } from 'react';
import { withRouter, useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';
import Api from '../../../Services/Api';
import Alert from '../../../Services/Alert';

export const ProfileUpdate = (props) => {

    var history = useHistory();
    var [profile, setProfile] = useState(null);

    var [sessadminname, setSessadminname] = useState('');
    var [sessAdminProfile, setSessAdminProfile] = useState('../assets/images/users/avatar-1.jpg');
    const [adminInfo, setAdminInfo] = useState({
        firstname: "",
        lastname: "",
        email: "",
        status: "false"
    });

    const { register, handleSubmit, formState: { errors }, watch, reset, setValue } = useForm({ mode: "onChange", defaultValues: adminInfo });
   
    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append("profile", profile);
        // console.log(data);
        for ( var key in data ) {
            formData.append(key, data[key]);
        }

        await Api.post('profile-update', formData).then((res) => res.data).then(res => {
            if (res.status) {
                Alert.success(res.message);
                setTimeout(function () {
                    Api.post('logout').then((res) => res.data).then(res => {
                        if (res.status) {
                            localStorage.clear();
                            history.push("/admin/login");
                        } else {
                            Alert.error(res.message)
                        }
                    }).catch(error => {
                        Alert.error(error.message)
                    })
                }, 1500);
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        });
    }
    useEffect(() => {
        if (localStorage.getItem('admin')) {
            var sessData = localStorage.getItem('admin');
            sessData = JSON.parse(sessData);
            var sFullname = sessData.firstname + ' ' + sessData.lastname;
            setSessadminname(sFullname);
            if(sessData.profile){
                var adminProfile = JSON.parse(localStorage.getItem('profilePath')).concat(sessData.profile);
                setSessAdminProfile(adminProfile);
            }

            let adminData = {
                firstname: sessData.firstname,
                lastname: sessData.lastname,
                email: sessData.email,
                status: sessData.status.toString()
            }
            setAdminInfo(adminData);
            reset(adminData);
        }
    }, []);

    const handleFileSelect = (event) => {
        setProfile(event.target.files[0])
    }

    return (
        <>
            <section className="main-content userlist_main_sec">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-4">
                                <div className="card overflow-hidden">
                                    <div className="bg-primary bg-soft">
                                        <div className="row">
                                            <div className="col-7">
                                                <div className="text-primary p-3">
                                                    <h5 className="text-primary">Good Morning !</h5>
                                                </div>
                                            </div>
                                            <div className="col-5 align-self-end">
                                                <img src="../assets/images/profile-img.png" alt="" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body pt-0">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="avatar-md profile-user-wid mb-4">
                                                    <img src={sessAdminProfile} alt="" className="img-thumbnail rounded-circle" />
                                                </div>
                                                <h5 className="font-size-15 col-md-12">{sessadminname}</h5>
                                                {/* <p className="text-muted mb-0 text-truncate">UI/UX Designer</p> */}
                                            </div>

                                            {/* <div className="col-sm-8">
                                                <div className="pt-4">

                                                    <div className="row">
                                                        <div className="col-6">
                                                            <h5 className="font-size-15">125</h5>
                                                            <p className="text-muted mb-0">Projects</p>
                                                        </div>
                                                        <div className="col-6">
                                                            <h5 className="font-size-15">$1245</h5>
                                                            <p className="text-muted mb-0">Revenue</p>
                                                        </div>
                                                    </div>
                                                    <div className="mt-4">
                                                        <a href="javascript: void(0);" className="btn btn-primary waves-effect waves-light btn-sm">View Profile <i className="mdi mdi-arrow-right ms-1"></i></a>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="col-xl-8">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h4 className="card-title mb-4">Profile Update</h4>
                                                <Form id="profileForm" onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <Form.Group controlId="formFile" className="mb-3">
                                                                <Form.Label>Profile</Form.Label>
                                                                {/* <Form.Control type="file" className="form-control" name="profile" onChange={handleFileSelect} /> */}
                                                                <input type="file" className="form-control" name="profile" onChange={handleFileSelect} />
                                                                {errors.profile && errors.profile.type === "required" && <div className="error">Profile is required.</div>}
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>First Name</Form.Label>
                                                                <Form.Control type="text" className="form-control" placeholder="Enter first name" autoComplete="firstname" {...register("firstname", { required: true })} />
                                                                {errors.firstname && errors.firstname.type === "required" && <div className="error">First Name is required.</div>}
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Last Name</Form.Label>
                                                                <Form.Control type="text" className="form-control" placeholder="Enter last name" autoComplete="lastname" {...register("lastname", { required: true })} />
                                                                {errors.lastname && errors.lastname.type === "required" && <div className="error">Last Name is required.</div>}
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Email</Form.Label>
                                                                <Form.Control type="email" className="form-control" placeholder="Enter email" autoComplete="email" {...register("email", { required: true })} />
                                                                {errors.email && errors.email.type === "required" && <div className="error">Email is required.</div>}
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="btn-submit">
                                                        <Button className="btn btn-primary w-md" type="submit">Submit</Button>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )

}
export default withRouter(ProfileUpdate);