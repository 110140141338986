import '../../../loader.css';

const Loader = (props) => {
  const { stateLoader } = props;
  // console.log("fire "+ stateLoader);
  return (
    <>
      {(stateLoader == 1) &&  (
        <div className="loader-wrapper">
          <div className="loader" >
            <img src="/web/assets/images/logo.png" width="100px" height="100px"/>
          </div>
        </div>
      )}
    </>
  );
};

export default Loader;
