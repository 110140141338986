import React, { useState, useEffect } from 'react';
import { withRouter, useHistory, useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';
import { Editor } from 'react-bootstrap-editor';
import moment from 'moment';
import SelectSearch from '../../../Components/SelectSearch';

import Alert from '../../../Services/Alert';
import CallingMethod from '../../../Services/CallingMethod';
import { arrayToStringMore, capitalizeFirst } from '../../../Services/Custom';
const BaseRouteName = "hire-request";

export const View = (props) => {
    const history = useHistory();
   
    const { id } = useParams();
    const [editInfo, setEditInfo] = useState(null);
    const [mediaPath, setMediaPath] = useState('');
    useEffect(() => {
        CallingMethod.webGetData('api/admin/'+BaseRouteName+'/view/'+id).then((res) => res.data).then(res => {
            if (res.status) {
                if (typeof res.data !== 'undefined') {
                   
                    var schedule_call = (res.data.schedule_call.length > 0)?res.data.schedule_call[0]:null;
                    if(schedule_call){
                        // let year = schedule_call.year;
                        // let month = schedule_call.month;
                        // let day = schedule_call.day;
                        // let hour = schedule_call.hour;
                        // let minute = schedule_call.minute;
                        // let stringDate = ''+year+'-'+month+'-'+day+' '+hour+':'+minute+'';
                        schedule_call = moment(schedule_call).format("MMMM, DD, YYYY LT");
                        res.data.schedule_call = schedule_call;
                    }else{
                        res.data.schedule_call = '';
                    }
                    setMediaPath(res.path);
                    setEditInfo(res.data);

                }
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        })
    }, []);
   

    return (
        <>
            <section className="main-content slideradd_main_sec">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h3>Details : </h3>

                                        <div className="card">
                                            <div className="card-body row flex">
                                                <div className="col-md-3  mt-4">
                                                    <span>Email : <b>{(editInfo)?editInfo.email:''}</b></span>
                                                </div>
                                                <div className="col-md-3  mt-4">
                                                    <span>Service : <b>{(editInfo)?editInfo.service:''}</b></span>
                                                </div>
                                                <div className="col-md-3  mt-4">
                                                    <span>Hire Type : <b>{(editInfo)?editInfo.hire_for:''}</b></span>
                                                </div>
                                                <div className="col-md-3  mt-4">
                                                    <span>Job Description : <b>{(editInfo)?editInfo.job_description:''}</b></span>
                                                </div>
                                                <div className="col-md-3  mt-4">
                                                    {/* <span>Job Description Media : <b>{(editInfo && editInfo.job_description_attachment != null) ? `<a href=${mediaPath + editInfo.job_description_attachment} target="blank"><img src=${mediaPath + editInfo.job_description_attachment} width="150px" height="150px"/></a>` : ' No Media'}</b></span> */}
                                                    <span>Job Description Media : <b> <a href={(editInfo && editInfo.job_description_attachment != null) ? mediaPath+editInfo.job_description_attachment:'#'} target="blank"><img src={(editInfo && editInfo.job_description_attachment != null) ? mediaPath+editInfo.job_description_attachment:''} alt="No Media" width="150px" height="150px"/></a></b></span>
                                                </div>
                                                <div className="col-md-12 mt-4">
                                                    <span>Look Into Developer : <b>{(editInfo)?arrayToStringMore((editInfo.looking_into_developer), 200, false):''}</b></span>
                                                </div>
                                                <div className="col-md-6 mt-4">
                                                    <span>Other Skills : <b>{(editInfo)?arrayToStringMore((editInfo.other_skills), 200, false):''}</b></span>
                                                </div>
                                                
                                                <div className="col-md-6 container mt-4">
                                                    <span>Tech Expert : <b>{(editInfo)?arrayToStringMore((editInfo.tech_expert), 200, false):''}</b></span>
                                                </div>
                                                
                                                <div className="col-md-3 mt-4">
                                                    <span>Project Duration : <b>{(editInfo)?editInfo.projectDuration+' Days':''}</b></span>
                                                </div>
                                                <div className="col-md-3 mt-4">
                                                    <span>Tentative Budget : <b>{(editInfo)?editInfo.tentativeBudget+'K':''}</b></span>
                                                </div>
                                                <hr/>
                                                <h3 className="col-md-12 mt-4">Company Details : </h3>
                                                <div className="col-md-3 mt-4">
                                                    <span>Company Name : <b>{(editInfo)?editInfo.company_name:''}</b></span>
                                                </div>
                                                <div className="col-md-3 mt-4">
                                                    <span>Company Presence : <b>{(editInfo)?editInfo.company_presence:''}</b></span>
                                                </div>
                                                <div className="col-md-3 mt-4">
                                                    <span>Company Type : <b>{(editInfo)?editInfo.companyType:''}</b></span>
                                                </div>
                                                <div className="col-md-3 mt-4">
                                                    <span>Company Listed : <b>{(editInfo)?editInfo.companyListed:''}</b></span>
                                                </div>
                                                <div className="col-md-3 mt-4">
                                                    <span>Company Funded : <b>{(editInfo)?editInfo.companyFunded:''}</b></span>
                                                </div>
                                                <div className="col-md-3 mt-4">
                                                    <span>Company Strength  : <b>{(editInfo)?editInfo.employee_strength:''}</b></span>
                                                </div>
                                                <div className="col-md-3 mt-4">
                                                    <span>Company Contact  : <b>{(editInfo)?editInfo.contact_number:''}</b></span>
                                                </div>

                                                <hr/>
                                                <h3 className="col-md-12 mt-4">Schedule Call On : </h3>
                                                <div className="col-md-3 mt-4">
                                                    <h5> <b>{(editInfo)?editInfo.schedule_call:''}</b></h5>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default withRouter(View);