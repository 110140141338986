import React, { useState, useEffect } from 'react';
import { withRouter, useHistory, useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Alert from '../../../Services/Alert';
import CallingMethod from '../../../Services/CallingMethod';
import { capitalizeFirst } from '../../../Services/Custom';
const BaseRouteName = "client";

export const Edit = (props) => {
    const history = useHistory();
    var [images, setImages] = useState([]);
    const [switchState, setSwitchState] = useState(false);
    const { id } = useParams();
    const [editInfo, setEditInfo] = useState({
        name: "",
        website:"",
        redirection:false
    });
    const { register, handleSubmit, formState: { errors }, watch, reset, setValue } = useForm({ mode: "onChange", defaultValues: editInfo });
    var [updateid, setUpdateID] = useState(0);
    useEffect(() => {
        CallingMethod.edit(BaseRouteName, id).then((res) => res.data).then(res => {
            if (res.status) {
                if (typeof res.data !== 'undefined') {
                    let editData = {
                        name: res.data.name,
                        website: res.data.website,
                        redirection: res.data.redirection.toString(),
                    }
                    setEditInfo(editData)
                    setSwitchState((res.data.redirection == 1?true:false));
                    reset(editData);
                    setUpdateID(res.data._id);
                }
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        })
    }, []);
    const onUpdate = async (data) => {
        data.id = updateid;
        data.redirection = (switchState == true)?1:0;
        const formData = new FormData();
        
        
        if(images.length > 0){
            for (let i = 0; i < images.length; i++) {
                const element = images[i];
                // console.log(element);
                formData.append(`${element['inputName']}`, element);
            }
        }
        for ( var key in data ) {
            if(key == 'icon'){
                continue;
            }else{
                formData.append(key, data[key]);
            }
        }
        CallingMethod.update(BaseRouteName, formData).then((res) => res.data).then(res => {
            if (res.status) {
                Alert.success(res.message);
                history.push('/admin/'+BaseRouteName);
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        })
    }

    const handleFileSelect = (event) => {
        // console.log(event.target.files[0]);
        let newFile = event.target.files[0];
        newFile.inputName = event.target.name;
        setImages([...images, newFile]);
    }
    const handleChange=(e)=>{
        setSwitchState(!switchState)
    }

    return (
        <>
            <section className="main-content slideradd_main_sec">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">{capitalizeFirst(BaseRouteName)} Edit</h4>
                                        <Form className="needs-validation" noValidate onSubmit={handleSubmit(onUpdate)}>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3" controlId="name">
                                                        <Form.Label>Name</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Name" className="form-control" {...register("name", {required: true, maxLength: 80})} />
                                                        <div className="error">
                                                            {errors.name?.type === 'required' && "Name is required"}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Form.Group controlId="formFile" className="mb-3">
                                                        <Form.Label>{capitalizeFirst('icon')}</Form.Label>
                                                        <input type="file" className="form-control" name="icon" onChange={handleFileSelect}/>
                                                        {/* <Form.Control type="file" className="form-control" onChange={handleFileSelect} name="icon" {...register("icon", {required:true})} /> */}
                                                        <div className="error">
                                                            {errors.icon?.type === 'required' && capitalizeFirst('icon')+ " is required"}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3" controlId="website">
                                                        <Form.Label>{capitalizeFirst('website')}</Form.Label>
                                                        <Form.Control type="url" pattern="^\S+$" placeholder={`Enter ${capitalizeFirst('website')}`} className="form-control" {...register("website", {required: false, maxLength: 80, url:true })} />
                                                        <div className="error">
                                                            {errors.website?.type === 'required' && capitalizeFirst('website')+ " is required"}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3" controlId="redirection">
                                                        <Form.Label>{capitalizeFirst('redirection')}</Form.Label>
                                                        <BootstrapSwitchButton
                                                            className="form-control mt-4"
                                                            checked={switchState}
                                                            onlabel='ON'
                                                            offlabel='OFF'
                                                            size='lg'
                                                            onstyle="primary" 
                                                            offstyle="warning"
                                                            onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="btn-submit">
                                                <Button className="btn btn-primary w-md" type="submit">Submit</Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default withRouter(Edit);