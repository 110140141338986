import React, { useState, useEffect } from 'react';
import { withRouter, useHistory, useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';

import Alert from '../../../Services/Alert';
import CallingMethod from '../../../Services/CallingMethod';
import { capitalizeFirst } from '../../../Services/Custom';
const BaseRouteName = "user";

export const Edit = (props) => {
    const history = useHistory();
    var [images, setImages] = useState([]);


    const { id } = useParams();
    const [editInfo, setEditInfo] = useState({
        fullname: "",
        email: "",
        country_code: "",
        mobile_no: "",
        status: "false"
    });
    const { register, handleSubmit, formState: { errors }, watch, reset, setValue } = useForm({ mode: "onChange", defaultValues: editInfo });
    var [updateid, setUpdateID] = useState(0);
    useEffect(() => {
        CallingMethod.edit(BaseRouteName, id).then((res) => res.data).then(res => {
            if (res.status) {
                if (typeof res.data !== 'undefined') {
                    let editData = {
                        fullname: res.data.fullname,
                        email: res.data.email,
                        country_code: parseInt(res.data.country_code),
                        mobile_no: res.data.mobile_no,
                        status: res.data.status.toString()
                    }

                    setEditInfo(editData)
                    reset(editData);
                    setUpdateID(res.data._id);
                }
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        })
    }, []);
    const onUpdate = async (data) => {
        data.id = updateid;
        const formData = new FormData();


        if (images.length > 0) {
            for (let i = 0; i < images.length; i++) {
                const element = images[i];
                // console.log(element);
                formData.append(`${element['inputName']}`, element);
            }
        }
        for (var key in data) {
            if (key == 'profile') {
                continue;
            } else {
                formData.append(key, data[key]);
            }
        }
        CallingMethod.update(BaseRouteName, formData).then((res) => res.data).then(res => {
            if (res.status) {
                Alert.success(res.message);
                history.push('/admin/' + BaseRouteName);
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        })
    }

    const handleFileSelect = (event) => {
        // console.log(event.target.files[0]);
        let newFile = event.target.files[0];
        newFile.inputName = event.target.name;
        setImages([...images, newFile]);
    }

    return (
        <>
            <section className="main-content slideradd_main_sec">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">{capitalizeFirst(BaseRouteName)} Edit</h4>
                                        <Form className="needs-validation" noValidate onSubmit={handleSubmit(onUpdate)}>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3" controlId="fullname">
                                                        <Form.Label>{capitalizeFirst('fullname')}</Form.Label>
                                                        <Form.Control type="text" placeholder={`Enter ${capitalizeFirst('fullname')}`} className="form-control" {...register("fullname", { required: true, maxLength: 200 })} />
                                                        <div className="error">
                                                            {errors.fullname?.type === 'required' && capitalizeFirst('fullname') + " is required"}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Form.Group controlId="profile" className="mb-3">
                                                        <Form.Label>{capitalizeFirst('profile')}</Form.Label>
                                                        <input type="file" className="form-control" name="profile" onChange={handleFileSelect} />
                                                        {errors.profile && errors.profile.type === "required" && <div className="error">{capitalizeFirst('profile')} is required.</div>}
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3" controlId="mobile_no">
                                                        <Form.Label>{capitalizeFirst('mobile_no')}</Form.Label>
                                                        <div className="row">
                                                            <div className="col-lg-3">
                                                                <Form.Control type="number" pattern="^-?[0-9]\d*\.?\d*$" placeholder="91" className="form-control" {...register("country_code", { required: true, valueAsNumber: true, maxLength: 80 })} />
                                                                <div className="error">
                                                                    {errors.country_code?.type === 'required' && capitalizeFirst('country_code') + " is required"}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-9">
                                                                <Form.Control type="text" placeholder={`Enter ${capitalizeFirst('mobile_no')}`} className="form-control" {...register("mobile_no", { required: true, maxLength: 80 })} />
                                                                <div className="error">
                                                                    {errors.mobile_no?.type === 'required' && capitalizeFirst('mobile_no') + " is required"}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3" controlId="email">
                                                        <Form.Label>{capitalizeFirst('email')}</Form.Label>
                                                        <Form.Control type="email" placeholder={`Enter ${capitalizeFirst('email')}`} className="form-control" {...register("email", { required: true, email: true, maxLength: 200 })} />
                                                        <div className="error">
                                                            {errors.email?.type === 'required' && capitalizeFirst('email') + " is required"}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                              
                                            </div>
                                            <div className="btn-submit">
                                                <Button className="btn btn-primary w-md" type="submit">Submit</Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default withRouter(Edit);