import React, { useState, useEffect } from 'react';
import { withRouter, useHistory, useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';
import { Editor } from 'react-bootstrap-editor';
import moment from 'moment';

import Alert from '../../../Services/Alert';
import CallingMethod from '../../../Services/CallingMethod';
import { capitalizeFirst } from '../../../Services/Custom';
import ChipInput from '../../../Components/ChipInput';
const BaseRouteName = "journey";

export const Edit = (props) => {
    const history = useHistory();
    var [images, setImages] = useState([]);
    const [editor, setEditor] = useState("");


    const { id } = useParams();
    const [editInfo, setEditInfo] = useState({
        title: "",
        date: moment(new Date()).format('YYYY-MM-DD')
    });
    const { register, handleSubmit, formState: { errors }, watch, reset, setValue } = useForm({ mode: "onChange", defaultValues: editInfo });
    var [updateid, setUpdateID] = useState(0);
    useEffect(() => {
        CallingMethod.edit(BaseRouteName, id).then((res) => res.data).then(res => {
            if (res.status) {
                if (typeof res.data !== 'undefined') {
                    let editData = {
                        title: res.data.title,
                        date: moment(res.data.date).format('YYYY-MM-DD'),
                    }
                    setEditor(res.data.about);
                    setValue("about", res.data.about);

                    setEditInfo(editData)
                    reset(editData);
                    setUpdateID(res.data._id);
                }
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        })
    }, []);
    const onUpdate = async (data) => {
        data.id = updateid;
        const formData = new FormData();


        if (images.length > 0) {
            for (let i = 0; i < images.length; i++) {
                const element = images[i];
                // console.log(element);
                formData.append(`${element['inputName']}`, element);
            }
        }
        for (var key in data) {
            if (key == 'thumbnail') {
                continue;
            } else {
                formData.append(key, data[key]);
            }
        }
        CallingMethod.update(BaseRouteName, formData).then((res) => res.data).then(res => {
            if (res.status) {
                Alert.success(res.message);
                history.push('/admin/' + BaseRouteName);
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        })
    }

    const handleFileSelect = (event) => {
        // console.log(event.target.files[0]);
        let newFile = event.target.files[0];
        newFile.inputName = event.target.name;
        setImages([...images, newFile]);
    }

    const handleEditor = (value) => {
        setEditor(value);
        setValue("about", value);
    }

    return (
        <>
            <section className="main-content slideradd_main_sec">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">{capitalizeFirst(BaseRouteName)} Edit</h4>
                                        <Form className="needs-validation" noValidate onSubmit={handleSubmit(onUpdate)}>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3" controlId="title">
                                                        <Form.Label>{capitalizeFirst('title')}</Form.Label>
                                                        <Form.Control type="text" placeholder={`Enter ${capitalizeFirst('title')}`} className="form-control" {...register("title", { required: true, maxLength: 200 })} />
                                                        <div className="error">
                                                            {errors.title?.type === 'required' && capitalizeFirst('title') + " is required"}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Form.Group controlId="thumbnail" className="mb-3">
                                                        <Form.Label>{capitalizeFirst('thumbnail')}</Form.Label>
                                                        <input type="file" className="form-control" name="thumbnail" onChange={handleFileSelect} />
                                                        {errors.thumbnail && errors.thumbnail.type === "required" && <div className="error">{capitalizeFirst('thumbnail')} is required.</div>}
                                                    </Form.Group>
                                                </div>
                                                
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3" controlId="date">
                                                        <Form.Label>{capitalizeFirst('date')}</Form.Label>
                                                        <Form.Control type="date" placeholder={`Enter ${capitalizeFirst('date')}`} className="form-control" {...register("date", { required: true })} />
                                                        <div className="error">
                                                            {errors.date?.type === 'required' && capitalizeFirst('date') + " is required"}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                               
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3" controlId="about">
                                                        <Form.Label>{capitalizeFirst('about')}</Form.Label>
                                                        <Editor value={editor} className="form-control" onChange={handleEditor} />
                                                        <input type="hidden" name="about" className="form-control" {...register("about", { required: true, value: editor })} />
                                                        <div className="error">
                                                            {errors.about?.type === 'required' && capitalizeFirst('about') + " is required"}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="btn-submit">
                                                <Button className="btn btn-primary w-md" type="submit">Submit</Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default withRouter(Edit);