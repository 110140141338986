import React, { useState } from 'react';
import {  withRouter, useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { Button, Form} from 'react-bootstrap';
import Alert from '../../../Services/Alert';
import CallingMethod from '../../../Services/CallingMethod';
import { capitalizeFirst } from '../../../Services/Custom';
const BaseRouteName = "technology";

export const Add = (props) => {
  var currentRoutePath = props.match.path.split('/');
  let currentRouteName = currentRoutePath[currentRoutePath.length - 1];

  const history = useHistory();
  var [images, setImages] = useState([]);

  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  const onSubmit = async (data) => {
    const formData = new FormData();
    // formData.append("profile", profile);
    // console.log(images);
    
    if(images.length > 0){
      for (let i = 0; i < images.length; i++) {
        const element = images[i];
        formData.append(`${element['inputName']}`, element);
      }

    }
    for ( var key in data ) {
        formData.append(key, data[key]);
    }

    CallingMethod.save(BaseRouteName, formData).then((res) => res.data).then(res => {
      if(res.status){
        Alert.success(res.message)
        history.push('/admin/'+BaseRouteName);
      }else{
        Alert.error(res.message)
      }
    }).catch(error => {
      Alert.error(error.message)
    })
  } 

  const handleFileSelect = (event) => {
    let newFile = event.target.files[0];
    newFile.inputName = event.target.name;
    setImages([...images, newFile]);
  }
  return (
    <>
      <section className="main-content slideradd_main_sec">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">{capitalizeFirst(BaseRouteName)} Add</h4>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-lg-6">
                          <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter Name" className="form-control" {...register("name", {required: true, maxLength: 80})} />
                            <div className="error">
                                {errors.name?.type === 'required' && "Name is required"}
                            </div>
                          </Form.Group>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                              <Form.Group controlId="formFile" className="mb-3">
                                  <Form.Label>{capitalizeFirst('Logo icon (50*50)')}</Form.Label>
                                  <input type="file" className="form-control" name="icon" onChange={handleFileSelect} />
                                  {errors.icon && errors.icon.type === "required" && <div className="error">{capitalizeFirst('icon')} is required.</div>}
                              </Form.Group>
                          </div>
                        </div>
                        
                      </div>
                      <div className="btn-submit">
                        <Button className="btn btn-primary w-md" type="submit">Submit</Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default withRouter(Add);