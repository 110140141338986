import React, { useState } from 'react';
import {  withRouter, useHistory } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
import { Button, Form} from 'react-bootstrap';
import { Rating } from 'react-simple-star-rating';
import { Editor } from 'react-bootstrap-editor';

import Alert from '../../../Services/Alert';
import CallingMethod from '../../../Services/CallingMethod';
import { capitalizeFirst } from '../../../Services/Custom';
// import EditorArea from '../../../Components/EditorArea-bkp';
const BaseRouteName = "client-story";



export const Add = (props) => {

  var currentRoutePath = props.match.path.split('/');
  let currentRouteName = currentRoutePath[currentRoutePath.length - 1];

  const history = useHistory();
  var [images, setImages] = useState([]);

  const [rating, setRating] = useState(2) // initial rating value
  const [editor, setEditor] = useState("");

  // Catch Rating value
  const handleRating = (rate) => {
    // console.log(rate);
    setRating(rate)
    setValue("rating", rate);
    // Some logic
  }
  const handleEditor = (value) => {
    // console.log(value);
    setEditor(value);
    setValue("review", value);
  }


  const { register, handleSubmit, formState: { errors }, watch, control, setValue } = useForm();
  const onSubmit = async (data) => {
    console.log(data);
    const formData = new FormData();
    for ( var key in data ) {
        formData.append(key, data[key]);
    }
    if(images.length > 0){
      for (let i = 0; i < images.length; i++) {
        const element = images[i];
        formData.append(`${element['inputName']}`, element);
      }
    }
    
    CallingMethod.save(BaseRouteName, formData).then((res) => res.data).then(res => {
      if(res.status){
        Alert.success(res.message)
        history.push('/admin/'+BaseRouteName);
      }else{
        Alert.error(res.message)
      }
    }).catch(error => {
      Alert.error(error.message)
    })
  } 

  const handleFileSelect = (event) => {
    let newFile = event.target.files[0];
    newFile.inputName = event.target.name;
    setImages([...images, newFile]);
  }
  return (
    <>
      <section className="main-content slideradd_main_sec">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">{capitalizeFirst(BaseRouteName)} Add</h4>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-lg-6">
                          <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter Name" className="form-control" {...register("name", {required:true, maxLength: 80})} />
                            <div className="error">
                                {errors.name?.type === 'required' && "Name is required"}
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                            <Form.Group controlId="profile" className="mb-3">
                                <Form.Label>{capitalizeFirst('profile')}</Form.Label>
                                <input type="file" className="form-control" name="profile" onChange={handleFileSelect}/>
                                {errors.profile && errors.profile.type === "required" && <div className="error">{capitalizeFirst('profile')} is required.</div>}
                            </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group className="mb-3" controlId="company">
                            <Form.Label>{capitalizeFirst('company')}</Form.Label>
                            <Form.Control type="text" placeholder={`Enter ${capitalizeFirst('company')}`} className="form-control" {...register("company", {required: true })} />
                            <div className="error">
                                {errors.company?.type === 'required' && capitalizeFirst('company')+ " is required"}
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group className="mb-3" controlId="position">
                            <Form.Label>{capitalizeFirst('position')}</Form.Label>
                            <Form.Control type="text" placeholder={`Enter ${capitalizeFirst('position')}`} className="form-control" {...register("position", {required: true })} />
                            <div className="error">
                                {errors.position?.type === 'required' && capitalizeFirst('position')+ " is required"}
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group className="mb-3" controlId="rating">
                            <Form.Label>{capitalizeFirst('rating')}</Form.Label>
                            
                                <Rating
                                  onClick={handleRating}
                                  initialValue={rating}
                                  allowFraction={false}
                                  iconsCount={5}
                                  size={40}
                                  label
                                  transition
                                  fillColor='orange'
                                  emptyColor='gray'
                                  className='foo' // Will remove the inline style if applied
                                  {...register("rating", {required: true, value:rating })}
                                />
                              
                             {/* <input type="hidden" {...register("rating", {required: true, value:rating })} /> */}
                            <div className="error">
                                {errors.rating?.type === 'required' && capitalizeFirst('rating')+ " is required"}
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group className="mb-3" controlId="review">
                            <Form.Label>{capitalizeFirst('review')}</Form.Label>
                            {/* <Controller
                              name="review"
                              rules={{ required: true }}
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <Editor
                                  value={value}
                                  onChange={onChange}
                                  className="form-control"
                                />
                              )}
                            /> */}
                            <Editor value={editor} className="form-control" onChange={handleEditor} />
                            <input type="hidden" value={editor} name="review" className="form-control" {...register("review", { required: true })} />

                            {/* <input type="hidden" name="review" value={editor} className="form-control" {...register("review", {required: true })} /> */}
                            <div className="error">
                                {errors.review?.type === 'required' && capitalizeFirst('review')+ " is required"}
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="btn-submit">
                        <Button className="btn btn-primary w-md" type="submit">Submit</Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default withRouter(Add);