import React, { useState, useEffect } from 'react';
import { withRouter, useHistory, useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';
import { Editor } from 'react-bootstrap-editor';

import Alert from '../../../Services/Alert';
import CallingMethod from '../../../Services/CallingMethod';
import { capitalizeFirst } from '../../../Services/Custom';
const BaseRouteName = "FAQ";

export const Edit = (props) => {
    const history = useHistory();
    const [editor, setEditor] = useState("");


    const { id } = useParams();
    const [editInfo, setEditInfo] = useState({
        question: ""
    });
    const { register, handleSubmit, formState: { errors }, watch, reset, setValue } = useForm({ mode: "onChange", defaultValues: editInfo });
    var [updateid, setUpdateID] = useState(0);
    useEffect(() => {
        CallingMethod.edit(BaseRouteName, id).then((res) => res.data).then(res => {
            if (res.status) {
                if (typeof res.data !== 'undefined') {
                    let editData = {
                        question: res.data.question,
                    }
                    setEditor(res.data.answer);
                    setValue("answer", res.data.answer);

                    setEditInfo(editData)
                    
                    reset(editData);
                    setUpdateID(res.data._id);
                }
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        })
    }, []);
    const onUpdate = async (data) => {
        data.id = updateid;
        // const formData = new FormData();


       
        // for (var key in data) {
        //     if (key == 'profile') {
        //         continue;
        //     } else {
        //         formData.append(key, data[key]);
        //     }
        // }
        CallingMethod.update(BaseRouteName, data).then((res) => res.data).then(res => {
            if (res.status) {
                Alert.success(res.message);
                history.push('/admin/' + BaseRouteName);
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        })
    }
    const handleEditor = (value) => {
        setEditor(value);
        setValue("answer", value);
    }

    return (
        <>
            <section className="main-content slideradd_main_sec">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">{capitalizeFirst(BaseRouteName)} Edit</h4>
                                        <Form className="needs-validation" noValidate onSubmit={handleSubmit(onUpdate)}>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3" controlId="question">
                                                        <Form.Label>{capitalizeFirst('question')}</Form.Label>
                                                        <Form.Control type="text" placeholder={`Enter ${capitalizeFirst('question')}`} className="form-control" {...register("question", { required: true })} />
                                                        <div className="error">
                                                            {errors.question?.type === 'required' && capitalizeFirst('question') + " is required"}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3" controlId="answer">
                                                        <Form.Label>{capitalizeFirst('answer')}</Form.Label>
                                                        <Editor value={editor} className="form-control" onChange={handleEditor} />
                                                        <input type="hidden" name="answer" className="form-control" {...register("answer", { required: true, value: editor })} />
                                                        <div className="error">
                                                            {errors.answer?.type === 'required' && capitalizeFirst('answer') + " is required"}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="btn-submit">
                                                <Button className="btn btn-primary w-md" type="submit">Submit</Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default withRouter(Edit);