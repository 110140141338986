import Layout from './Pages/Admin/Include/Layout'
import WebsiteLayout from './Pages/Website/Include/WebsiteLayout'
import { BrowserRouter as Router, Route } from "react-router-dom";
import ScrollToTop from './ScrollToTop';
function App() {
  if (window.location.href.indexOf("admin") > -1) {
    //Admin Panel
    return (<>
      <Router>
        <ScrollToTop />
        <Layout/>
      </Router>
    </>)
  }else{
    //Front
    return (
      <>
        <Router>
          <ScrollToTop />
          <WebsiteLayout/>
        </Router>
      </>
    )
  }
  
}

export default App;
