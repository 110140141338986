import { memo, useState, useEffect } from "react";
import { Button, Form} from 'react-bootstrap';
import Chip from "@material-ui/core/Chip";
import { capitalizeFirst } from '../Services/Custom';

const ChipInput = ({inputName, register, inputValue, placeholder='', required=true, setValue=""}) => {

  const [chips, setChips] = useState(inputValue);
  const [chipWrite, setChipWrite] = useState("");

  useEffect(() => {
    if(inputValue.length > 0){
      // console.log(inputValue);
      if(inputValue != chips){
        setChips(inputValue);
      }
      
    }
  }, [inputValue]);

  // console.log(inputValue);
  const handleRemove = (value) => {
    // console.log(value);
    let filteredChip = chips.filter(item => item != value);
    setChips(filteredChip);
    setValue(inputName, filteredChip);
    
  };

  const handleChips = (value) => {
    if(value == ""){
      return false;
    }
    if(chips.includes(value)){
      let filteredChip = chips.filter(item => item != value);
      setChips(filteredChip);
      setValue(inputName, filteredChip);
    }else{
      setChips([...chips, value]);
      setValue(inputName, [...chips, value]);
      // if(editInfo != ""){
      //   setEditInfo({...editInfo, [inputName]:chips});
      // }
    }
    // setChips((chips) => [...chips, value]);
    setChipWrite("");
    // e.target.name['category'].focus();
  }

  return (
    <>
        <div style={{ display: "block", padding: 10, background:"transparent" , border :"none"}}>
            {/* <h4>Add/Remove Multiple Category Here</h4> */}
            { chips.map((chip, index) => {
                return <Chip 
                  key={index} 
                  variant="outlined" 
                  label={chip} 
                  onClick={(e) => handleRemove(chip)} 
                  onDelete={(e) => handleRemove(chip)}
                />; 
            })}
        </div> 
        <Form.Control value={chipWrite} type="text" onChange={(e) => {setChipWrite(e.target.value)}} onKeyPress={(e) => {if(e.key === 'Enter') { e.preventDefault(); handleChips(e.target.value); }}} placeholder={(placeholder)?placeholder:`Enter ${capitalizeFirst(inputName)}`} className="form-control"/>
        <input type="hidden" name={inputName} defaultValue={chips} className="form-control" {...register(inputName, {required: required })} />
        
        
    </>
  );
};

export default memo(ChipInput);