import React, { useEffect, useState } from 'react'
// import {Modal, Button, Table, Form } from 'react-bootstrap';
// import logo from "../../src/assets/images/logo.png";
import PrivateRoute from '../../../Services/PrivateRoute';
import { withRouter, useHistory, Route, Switch } from "react-router-dom";
import * as Comp from "./Index";
import Loader from './Loader';
import Header from './Header';

function Layout(props) {
  const history = useHistory();
  const [accessToken, setaccessToken] = useState(localStorage.getItem('authToken')||'')
  const [isShowLoader, setIsShowLoader] = useState(0)
  
  useEffect(() => {
    if(localStorage.getItem('authToken'))
      setaccessToken(localStorage.getItem('authToken'))
    else
      setaccessToken(null)
  }, [props])
  const hideShowLoader = async (isshow = 0) => {
    setIsShowLoader(isshow);
  }

  return (
    <>
      <header>
        {/* after authentication need to implement this for menu */}
        {accessToken && accessToken!=="" && 
          <Header />
        }
      </header>
      <main>
        {/* <Loader stateLoader={isShowLoader}/> */}
        <Switch>
          <PrivateRoute path="/admin/" exact component={Comp.Login} />
          <Route path="/admin/login" exact component={Comp.Login} />
          <Route path="/admin/register" exact component={Comp.Register} />
          <Route path="/admin/forgot-password" exact component={Comp.Forgot} />
          <PrivateRoute path="/admin/dashboard" exact component={Comp.Dashboard} pageLoader={hideShowLoader}/>
          <PrivateRoute path="/admin/change-password" exact component={Comp.ChangePassword} />
          <PrivateRoute path="/admin/profile-update" exact component={Comp.ProfileUpdate} />

          {/* User Route */}
          <PrivateRoute path="/admin/user" exact component={Comp.Userlist} />
          <PrivateRoute path="/admin/user/add" exact component={Comp.UserAdd} />
          <PrivateRoute path="/admin/user/edit/:id" exact component={Comp.UserEdit} />

          {/* Technology Route */}
          <PrivateRoute path="/admin/technology" exact component={Comp.Technologylist} />
          <PrivateRoute path="/admin/technology/add" exact component={Comp.TechnologyAdd} />
          <PrivateRoute path="/admin/technology/edit/:id" exact component={Comp.TechnologyEdit} />

          {/* Clients Route */}
          <PrivateRoute path="/admin/client" exact component={Comp.Clientlist} />
          <PrivateRoute path="/admin/client/add" exact component={Comp.ClientAdd} />
          <PrivateRoute path="/admin/client/edit/:id" exact component={Comp.ClientEdit} />

          {/* Client Story Route */}
          <PrivateRoute path="/admin/client-story" exact component={Comp.ClientStorylist} />
          <PrivateRoute path="/admin/client-story/add" exact component={Comp.ClientStoryAdd} />
          <PrivateRoute path="/admin/client-story/edit/:id" exact component={Comp.ClientStoryEdit} />

          {/* Blogs Route */}
          <PrivateRoute path="/admin/blog" exact component={Comp.Bloglist} />
          <PrivateRoute path="/admin/blog/add" exact component={Comp.BlogAdd} />
          <PrivateRoute path="/admin/blog/edit/:id" exact component={Comp.BlogEdit} />
          

          {/* Founder Route */}
          <PrivateRoute path="/admin/founder" exact component={Comp.Founderlist} />
          <PrivateRoute path="/admin/founder/add" exact component={Comp.FounderAdd} />
          <PrivateRoute path="/admin/founder/edit/:id" exact component={Comp.FounderEdit} />
          

          {/* CompanyPiller Route */}
          <PrivateRoute path="/admin/piller-of-strength" exact component={Comp.CompanyPillerlist} />
          <PrivateRoute path="/admin/piller-of-strength/add" exact component={Comp.CompanyPillerAdd} />
          <PrivateRoute path="/admin/piller-of-strength/edit/:id" exact component={Comp.CompanyPillerEdit} />
          

          {/* Journey Route */}
          <PrivateRoute path="/admin/journey" exact component={Comp.Journeylist} />
          <PrivateRoute path="/admin/journey/add" exact component={Comp.JourneyAdd} />
          <PrivateRoute path="/admin/journey/edit/:id" exact component={Comp.JourneyEdit} />
          
          

          {/* UseCase Route */}
          <PrivateRoute path="/admin/usecase" exact component={Comp.UseCaselist} />
          <PrivateRoute path="/admin/usecase/add" exact component={Comp.UseCaseAdd} />
          <PrivateRoute path="/admin/usecase/edit/:id" exact component={Comp.UseCaseEdit} />

          

          {/* FAQ Route */}
          <PrivateRoute path="/admin/FAQ" exact component={Comp.FAQlist} />
          <PrivateRoute path="/admin/FAQ/add" exact component={Comp.FAQAdd} />
          <PrivateRoute path="/admin/FAQ/edit/:id" exact component={Comp.FAQEdit} />

          {/* Hire Developer Request Route */}
          <PrivateRoute path="/admin/hire-request" exact component={Comp.HireRequestlist} />
          <PrivateRoute path="/admin/hire-request/view/:id" exact component={Comp.HireRequestView} />

          
          <Route path="/admin/" component={Comp.Login} />
          {/* <Route path="/email-verify/:id?/:token?" exact component={Comp.EmailVerify} /> */}
          {/* <PrivateRoute path="/dashboard" exact component={Comp.Home} />
          <PrivateRoute path="/myprofile" exact component={Comp.Myprofile} changeProPic={changeProPic}/> */}

        </Switch>
      </main>
    </>
  );
}

export default withRouter(Layout);