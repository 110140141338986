import React, { useState, useEffect } from 'react';
import {  withRouter, useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { Button, Form} from 'react-bootstrap';
import { Editor } from 'react-bootstrap-editor';

import Alert from '../../../Services/Alert';
import CallingMethod from '../../../Services/CallingMethod';
import { capitalizeFirst } from '../../../Services/Custom';
import ChipInput from '../../../Components/ChipInput';
import QuillEditorArea from '../../../Components/QuillEditorArea';
const BaseRouteName = "blog";



export const Add = (props) => {

  var currentRoutePath = props.match.path.split('/');
  let currentRouteName = currentRoutePath[currentRoutePath.length - 1];

  const history = useHistory();
  var [images, setImages] = useState([]);
  const [editor, setEditor] = useState("");
 
  const handleEditor = (value) => {
    setEditor(value);
    setValue("description", value);
  }
  
  const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm();
  const onSubmit = async (data) => {
    const formData = new FormData();
    for ( var key in data ) {
        formData.append(key, data[key]);
    }
    if(images.length > 0){
      for (let i = 0; i < images.length; i++) {
        const element = images[i];
        formData.append(`${element['inputName']}`, element);
      }
    }
    
    CallingMethod.save(BaseRouteName, formData).then((res) => res.data).then(res => {
      if(res.status){
        Alert.success(res.message)
        history.push('/admin/'+BaseRouteName);
      }else{
        Alert.error(res.message)
      }
    }).catch(error => {
      Alert.error(error.message)
    })
  } 

  const handleFileSelect = (event) => {
    let newFile = event.target.files[0];
    newFile.inputName = event.target.name;
    setImages([...images, newFile]);
  }
  return (
    <>
      <section className="main-content slideradd_main_sec">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">{capitalizeFirst(BaseRouteName)} Add</h4>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-lg-6">
                          <Form.Group className="mb-3" controlId="title">
                            <Form.Label>{capitalizeFirst('title')}</Form.Label>
                            <Form.Control type="text" placeholder={`Enter ${capitalizeFirst('title')}`} className="form-control" {...register("title", {required:true, maxLength: 200})} />
                            <div className="error">
                                {errors.title?.type === 'required' && capitalizeFirst('title')+ " is required"}
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group className="mb-3" controlId="slug">
                            <Form.Label>{capitalizeFirst('slug')}</Form.Label>
                            <Form.Control type="text" placeholder={`Enter ${capitalizeFirst('slug')}`} className="form-control" {...register("slug", {required:true, maxLength: 200})} onKeyPress={(e) => !/[a-zA-Z0-9\-]+$/.test(e.key) && e.preventDefault()} />
                            <div className="error">
                                {errors.slug?.type === 'required' && capitalizeFirst('slug')+ " is required"}
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                            <Form.Group controlId="thumbnail" className="mb-3">
                                <Form.Label>{capitalizeFirst('thumbnail')} (2200*700)</Form.Label>
                                <input type="file" className="form-control" name="thumbnail" onChange={handleFileSelect}/>
                                {errors.thumbnail && errors.thumbnail.type === "required" && <div className="error">{capitalizeFirst('thumbnail')} is required.</div>}
                            </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group className="mb-3" controlId="author">
                            <Form.Label>{capitalizeFirst('author')}</Form.Label>
                            <Form.Control type="text" placeholder={`Enter ${capitalizeFirst('author')}`} className="form-control" {...register("author", {required: true })} />
                            <div className="error">
                                {errors.author?.type === 'required' && capitalizeFirst('author')+ " is required"}
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group className="mb-3" controlId="posted_on">
                            <Form.Label>{capitalizeFirst('posted_on')}</Form.Label>
                            <Form.Control type="date" placeholder={`Enter ${capitalizeFirst('posted_on')}`} className="form-control" {...register("posted_on", {required: true })} />
                            <div className="error">
                                {errors.posted_on?.type === 'required' && capitalizeFirst('posted_on')+ " is required"}
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group className="mb-3" controlId="category">
                            <Form.Label>{capitalizeFirst('category')}</Form.Label>
                            
                            {/* <ChipInput chips={chips} removeChip={setChips}/> */}
                            <ChipInput inputName='category' register={register} required={true}  setValue={setValue} inputValue={[]} placeholder="Category"/>
                            <div className="error">
                                {errors.category?.type === 'required' && capitalizeFirst('category')+ " is required"}
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group className="mb-3" controlId="description">
                            <Form.Label>{capitalizeFirst('description')}</Form.Label>
                            {/* <Editor value="" className="form-control" onChange={handleEditor}/> */}
                            <QuillEditorArea name={'description'} value={editor} onChange={handleEditor}/>
                            <input type="hidden" name="description" className="form-control" {...register("description", {required: true, value:editor })} />
                            <div className="error">
                                {errors.description?.type === 'required' && capitalizeFirst('description')+ " is required"}
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="btn-submit">
                        <Button className="btn btn-primary w-md" type="submit">Submit</Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default withRouter(Add);