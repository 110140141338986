import React, { useEffect, useState, useMemo } from 'react';
import { Link, withRouter } from "react-router-dom";
import { Dropdown, Modal, Button } from "react-bootstrap";
import Alert from '../../../Services/Alert';
import moment from 'moment';
//import {amountFormat} from '../../../Services/Custom';
import DataTable from 'react-data-table-component';
import CallingMethod from '../../../Services/CallingMethod';
import { arrayToStringMore, capitalizeFirst, lookupToCategoryBlog } from '../../../Services/Custom';
import { exportExcel } from '../Include/Export';

const BaseRouteName = "hire-request";
const listArrayLength = 1;

export const List = (props) => {

	const [show, setShow] = useState(false);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [search, setSearch] = useState("");
	const [status, setStatus] = useState("");
	const [isReset, setIsReset] = useState(0);
	const [iconPath, setIconPath] = useState("");
	const columns = useMemo(() => [
		{
			name: 'Name',
			selector: row => {
				return row.yourName
			},
		},
		{
			name: 'Email',
			selector: row => {
				return row.email;
			},
			style:{
				overflow: "hidden",
				textOverflow: "ellipsis",
				whiteSpace: "nowrap"
			}, 
		},
		
		{
			name: 'Company',
			selector: row => {
				return row.company_name;
			}
		},
		{
			name: 'Service',
			selector: row => {
				return row.service;
			}
		},
		{
			name: 'Hire Type',
			selector: row => {
				return row.hire_for;
			}
		},
		
		{
			name: 'Technology',
			className:'text-danger',
			selector: row => {
				let technology = (row.tech_expert)?arrayToStringMore((row.tech_expert), 2, false):null;
				
				return technology;
			}
		},
		{
			name: 'Action',
			selector: (row, ind) => {
				return <><Dropdown id={`drop_main_${ind}`} key={ind} rendermenuonmount={true.toString()}>
					<Dropdown.Toggle id={`drop_${ind}`} variant="secondary" ><i className='bx bx-list-ul' ></i></Dropdown.Toggle>

					<Dropdown.Menu>
						<Dropdown.Item as={Link} to={{ pathname: `/admin/${BaseRouteName}/view/${row._id}` }}><i className='bx bxs-eye' ></i>&nbsp;View</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown></>
			},
		},
	]
	);

	const handleClose = () => setShow(false);
	
	const handleChangeSearch = (e) => {
		setSearch(e.target.value);
	};
	const handleChangeStatus = (e) => {
		setStatus(e.target.value);
	};
	const handleFilter = () => {
		//userData(1);
		setIsReset(!isReset);
	};
	function handleResetFilter() {
		setSearch(""); setStatus("");
		//userData(1);
		setIsReset(0);
	}

	const userData = async page => {
		setLoading(true);
		var sendd = { page: page, size: perPage, search: search, status: status };
		const response = await CallingMethod.list(BaseRouteName, sendd);
		setData(response.data.data.docs);
		setTotalRows(response.data.total);
		setLoading(false);
		setIconPath(response.data.path);
	};
	const handlePageChange = page => {
		userData(page);
	};
	const handlePerRowsChange = async (newPerPage, page) => {
		setLoading(true);
		var sendd = { page: page, size: perPage, search: '' };
		const response = await CallingMethod.list(BaseRouteName, sendd)
		setData(response.data.data.docs);
		setPerPage(newPerPage);
		setLoading(false);
	};
	
	useEffect(() => {
		userData(1);
	}, [isReset]);

	const exportToExcel = () => {
		var sendd = { search: search, status: status };
		CallingMethod.export(BaseRouteName, sendd).then((res) => res.data).then(res => {
			if (res.status) {
				// console.log(res.data);
				exportExcel(res.data, capitalizeFirst(BaseRouteName)+ ' Export');
			} else {
				Alert.error(res.message)
			}
		}).catch(error => {
			Alert.error(error.message)
		})
	}
	// A super simple expandable component.
	const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;
	return (
		<>
			<section className="main-content userlist_main_sec">
				<div className="page-content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-12">
								<div className="card">
									<div className="card-body">
										<div className="header-text">
											<h4 className="card-title mb-4">{capitalizeFirst(BaseRouteName)}</h4>
											<div className="edit-btn">
												
												{/* <Button onClick={() => exportToExcel()} className="btn btn-success  mr-4 btn-sm btn-rounded waves-effect waves-light" style={{backgroundColor:'none'}}>Export</Button> */}
												
											</div>
										</div>
										
										<div className="row">
											<div className="col-lg-3 col-md-3">
												<input type="text" className="form-control" value={search} onChange={(e) => handleChangeSearch(e)} />
											</div>
											
											<div className="col-lg-3 col-md-3">
												<button type="button" className="btn btn-outline-primary mr-4 btn-md btn-rounded waves-effect waves-light" onClick={() => handleFilter()}>Filter</button>&nbsp;
												<button type="button" className="btn btn-outline-secondary mr-4 btn-md btn-rounded waves-effect waves-light" onClick={() =>handleResetFilter()}>Reset</button>
											</div>
										</div>
										<div className="table-responsive">
											<DataTable
												// title={capitalizeFirst(BaseRouteName)}
												columns={columns}
												data={data}
												highlightOnHover
												progressPending={loading}
												pagination
												paginationServer
												paginationTotalRows={totalRows}
												onChangeRowsPerPage={handlePerRowsChange}
												onChangePage={handlePageChange}
												// selectableRows
												// onSelectedRowsChange={({ selectedRows }) => console.log(selectedRows)}
												// expandableRows
												// expandableRowsComponent={ExpandedComponent}
											/>
										</div>
										{/* end table-responsive  */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			
		</>
	)
}

export default withRouter(List);