import React, { useState, useEffect } from 'react';
import { withRouter, useHistory, useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';
import { Rating } from 'react-simple-star-rating';
import { Editor } from 'react-bootstrap-editor';

import Alert from '../../../Services/Alert';
import CallingMethod from '../../../Services/CallingMethod';
import { capitalizeFirst } from '../../../Services/Custom';
const BaseRouteName = "client-story";

export const Edit = (props) => {
    const history = useHistory();
    var [images, setImages] = useState([]);
    const [rating, setRating] = useState(0) // initial rating value
    const [editor, setEditor] = useState("");


    const { id } = useParams();
    const [editInfo, setEditInfo] = useState({
        name: "",
        company: "",
        position: ""
    });
    const { register, handleSubmit, formState: { errors }, watch, reset, setValue } = useForm({ mode: "onChange", defaultValues: editInfo });
    var [updateid, setUpdateID] = useState(0);
    useEffect(() => {
        CallingMethod.edit(BaseRouteName, id).then((res) => res.data).then(res => {
            if (res.status) {
                if (typeof res.data !== 'undefined') {
                    let editData = {
                        name: res.data.name,
                        company: res.data.company,
                        position: res.data.position,
                    }
                    setRating(res.data.rating);
                    setEditor(res.data.review);
                    setValue("rating", (res.data.rating));
                    setValue("review", res.data.review);

                    setEditInfo(editData)
                    reset(editData);
                    setUpdateID(res.data._id);
                }
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        })
    }, []);
    const onUpdate = async (data) => {
        data.id = updateid;
        const formData = new FormData();


        if (images.length > 0) {
            for (let i = 0; i < images.length; i++) {
                const element = images[i];
                // console.log(element);
                formData.append(`${element['inputName']}`, element);
            }
        }
        for (var key in data) {
            if (key == 'profile') {
                continue;
            } else {
                formData.append(key, data[key]);
            }
        }
        CallingMethod.update(BaseRouteName, formData).then((res) => res.data).then(res => {
            if (res.status) {
                Alert.success(res.message);
                history.push('/admin/' + BaseRouteName);
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        })
    }

    const handleFileSelect = (event) => {
        // console.log(event.target.files[0]);
        let newFile = event.target.files[0];
        newFile.inputName = event.target.name;
        setImages([...images, newFile]);
    }
    // Catch Rating value
    const handleRating = (rate) => {
        console.log(rate);
        setRating((rate))
        setValue("rating", (rate));
        // Some logic
    }
    const handleEditor = (value) => {
        setEditor(value);
        setValue("review", value);
    }

    return (
        <>
            <section className="main-content slideradd_main_sec">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">{capitalizeFirst(BaseRouteName)} Edit</h4>
                                        <Form className="needs-validation" noValidate onSubmit={handleSubmit(onUpdate)}>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3" controlId="name">
                                                        <Form.Label>Name</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Name" className="form-control" {...register("name", { required: true, maxLength: 80 })} />
                                                        <div className="error">
                                                            {errors.name?.type === 'required' && "Name is required"}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Form.Group controlId="profile" className="mb-3">
                                                        <Form.Label>{capitalizeFirst('profile')}</Form.Label>
                                                        <input type="file" className="form-control" name="profile" onChange={handleFileSelect} />
                                                        {errors.profile && errors.profile.type === "required" && <div className="error">{capitalizeFirst('profile')} is required.</div>}
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3" controlId="company">
                                                        <Form.Label>{capitalizeFirst('company')}</Form.Label>
                                                        <Form.Control type="text" placeholder={`Enter ${capitalizeFirst('company')}`} className="form-control" {...register("company", { required: true })} />
                                                        <div className="error">
                                                            {errors.company?.type === 'required' && capitalizeFirst('company') + " is required"}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3" controlId="position">
                                                        <Form.Label>{capitalizeFirst('position')}</Form.Label>
                                                        <Form.Control type="text" placeholder={`Enter ${capitalizeFirst('position')}`} className="form-control" {...register("position", { required: true })} />
                                                        <div className="error">
                                                            {errors.position?.type === 'required' && capitalizeFirst('position') + " is required"}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3" controlId="rating">
                                                        <Form.Label>{capitalizeFirst('rating')}</Form.Label>
                                                        <Rating
                                                            onClick={handleRating}
                                                            initialValue={rating}
                                                            allowFraction={false}
                                                            iconsCount={5}
                                                            size={40}
                                                            label
                                                            transition
                                                            fillColor='orange'
                                                            emptyColor='gray'
                                                            className='foo' // Will remove the inline style if applied
                                                            {...register("rating", { required: true, value: rating })}
                                                        />
                                                        {/* {rating} */}
                                                        <div className="error">
                                                            {errors.rating?.type === 'required' && capitalizeFirst('rating') + " is required"}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3" controlId="review">
                                                        <Form.Label>{capitalizeFirst('review')}</Form.Label>
                                                        {/* <Form.Control as="textarea" rows={3} placeholder={`Enter ${capitalizeFirst('review')}`} className="form-control" {...register("review", {required: true })} /> */}
                                                        <Editor value={editor} className="form-control" onChange={handleEditor} />
                                                        <input type="hidden" value={editor} name="review" className="form-control" {...register("review", { required: true })} />
                                                        <div className="error">
                                                            {errors.review?.type === 'required' && capitalizeFirst('review') + " is required"}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="btn-submit">
                                                <Button className="btn btn-primary w-md" type="submit">Submit</Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default withRouter(Edit);