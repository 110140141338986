import React, { useState, useEffect } from 'react';
import { withRouter, useHistory, useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';
import { Editor,OriginalTools } from 'react-bootstrap-editor';
import moment from 'moment';

import Alert from '../../../Services/Alert';
import CallingMethod from '../../../Services/CallingMethod';
import { capitalizeFirst } from '../../../Services/Custom';
import ChipInput from '../../../Components/ChipInput';
import QuillEditorArea from '../../../Components/QuillEditorArea';
const BaseRouteName = "blog";

export const Edit = (props) => {
    const history = useHistory();
    var [images, setImages] = useState([]);
    const [editor, setEditor] = useState("");


    const { id } = useParams();
    const [editInfo, setEditInfo] = useState({
        title: "",
        slug: "",
        author: "",
        category: [],
        posted_on: moment(new Date()).format('YYYY-MM-DD')
    });
    const { register, handleSubmit, formState: { errors }, watch, reset, setValue } = useForm({ mode: "onChange", defaultValues: editInfo });
    var [updateid, setUpdateID] = useState(0);
    useEffect(() => {
        CallingMethod.edit(BaseRouteName, id).then((res) => res.data).then(res => {
            if (res.status) {
                if (typeof res.data !== 'undefined') {
                    let lookup = [];
                    const rowLookup = JSON.parse(JSON.stringify(res.data.blog_category));
                    if(rowLookup.length > 0){
                        for (let i = 0; i < rowLookup.length; i++) {
                            const element = rowLookup[i];
                            lookup.push(element.title);
                        }
                    }
                    let editData = {
                        title: res.data.title,
                        slug: res.data.slug,
                        author: res.data.author,
                        category: lookup,
                        posted_on: moment(res.data.posted_on).format('YYYY-MM-DD'),
                    }
                    setEditor(res.data.description);
                    setValue("description", res.data.description);
                    setEditInfo(editData)
                    reset(editData);
                    setUpdateID(res.data._id);
                }
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        })
    }, []);
    const onUpdate = async (data) => {
        data.id = updateid;
        const formData = new FormData();


        if (images.length > 0) {
            for (let i = 0; i < images.length; i++) {
                const element = images[i];
                // console.log(element);
                formData.append(`${element['inputName']}`, element);
            }
        }
        for (var key in data) {
            if (key == 'thumbnail') {
                continue;
            } else {
                formData.append(key, data[key]);
            }
        }
        CallingMethod.update(BaseRouteName, formData).then((res) => res.data).then(res => {
            if (res.status) {
                Alert.success(res.message);
                history.push('/admin/' + BaseRouteName);
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        })
    }

    const handleFileSelect = (event) => {
        // console.log(event.target.files[0]);
        let newFile = event.target.files[0];
        newFile.inputName = event.target.name;
        setImages([...images, newFile]);
    }

    const handleEditor = (value) => {
        setEditor(value);
        setValue("description", value);
    }

    return (
        <>
            <section className="main-content slideradd_main_sec">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">{capitalizeFirst(BaseRouteName)} Edit</h4>
                                        <Form className="needs-validation" noValidate onSubmit={handleSubmit(onUpdate)}>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3" controlId="title">
                                                        <Form.Label>{capitalizeFirst('title')}</Form.Label>
                                                        <Form.Control type="text" placeholder={`Enter ${capitalizeFirst('title')}`} className="form-control" {...register("title", { required: true, maxLength: 200 })} />
                                                        <div className="error">
                                                            {errors.title?.type === 'required' && capitalizeFirst('title') + " is required"}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3" controlId="slug">
                                                        <Form.Label>{capitalizeFirst('slug')}</Form.Label>
                                                        <Form.Control type="text" placeholder={`Enter ${capitalizeFirst('slug')}`} className="form-control" onKeyPress={(e) => !/[a-zA-Z0-9\-]+$/.test(e.key) && e.preventDefault()} {...register("slug", { required: true, maxLength: 200 })} />
                                                        <div className="error">
                                                            {errors.slug?.type === 'required' && capitalizeFirst('slug') + " is required"}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Form.Group controlId="thumbnail" className="mb-3">
                                                        <Form.Label>{capitalizeFirst('thumbnail')}</Form.Label>
                                                        <input type="file" className="form-control" name="thumbnail" onChange={handleFileSelect} />
                                                        {errors.thumbnail && errors.thumbnail.type === "required" && <div className="error">{capitalizeFirst('thumbnail')} is required.</div>}
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3" controlId="author">
                                                        <Form.Label>{capitalizeFirst('author')}</Form.Label>
                                                        <Form.Control type="text" placeholder={`Enter ${capitalizeFirst('author')}`} className="form-control" {...register("author", { required: true })} />
                                                        <div className="error">
                                                            {errors.author?.type === 'required' && capitalizeFirst('author') + " is required"}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3" controlId="posted_on">
                                                        <Form.Label>{capitalizeFirst('posted_on')}</Form.Label>
                                                        <Form.Control type="date" placeholder={`Enter ${capitalizeFirst('posted_on')}`} className="form-control" {...register("posted_on", { required: true })} />
                                                        <div className="error">
                                                            {errors.posted_on?.type === 'required' && capitalizeFirst('posted_on') + " is required"}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3" controlId="category">
                                                        <Form.Label>{capitalizeFirst('category')}</Form.Label>

                                                        {/* <ChipInput chips={chips} removeChip={setChips}/> */}
                                                        <ChipInput inputName='category' register={register} required={false}  setValue={setValue} inputValue={(editInfo.category.length > 0)?editInfo.category:[]} placeholder="Category"/>

                                                        <div className="error">
                                                            {errors.category?.type === 'required' && capitalizeFirst('category') + " is required"}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3" controlId="description">
                                                        <Form.Label>{capitalizeFirst('description')}</Form.Label>
                                                        {/* <Editor value={editor} className="form-control" tools={OriginalTools} imageUrls={imageUrls} imageUpload={handleImageUpload} onChange={handleEditor} /> */}
                                                        <QuillEditorArea name={'description'} value={editor} onChange={handleEditor}/>
                                                        <input type="hidden" name="description" className="form-control" {...register("description", { required: true, value: editor })} />
                                                        <div className="error">
                                                            {errors.description?.type === 'required' && capitalizeFirst('description') + " is required"}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="btn-submit">
                                                <Button className="btn btn-primary w-md" type="submit">Submit</Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default withRouter(Edit);