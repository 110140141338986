export const PageMetaData = {
    home: {
      page: 'Home Page',

      title: `Hire the World's Most
      Vetted Remote Developers
      - Code1 Dev`,

      description: `Code1 Dev is the IT staff hiring Cloud
      solution platform for companies to
      hire the most vetted remote
      developers & software engineers on
      full-time jobs basis`

    },
    about: {
      page: 'about',
      title: 'About Us - Code1 Dev',
      description: `Our vision of taking staff to next level
      by adapting remote cultures
      worldwide and opening global
      equality staff for IT Projects`
    },
    contact: {
      page: 'contact-us',
      title: `Hire Dedicated Full Time
      Remote Developers -
      Code1 Dev
      `,
      description: `We Hire dedicated remote
      developers across 50+ skills in 7
      days for the Clients. Join 100+
      companies that developers from
      Code1 Dev with a simple Hire
      Process.
      `
    },
    fullStack: {
      page: 'fullStack',
      title: `Hire Dedicated Remote Full
      Stack Developer India -
      Code1 Dev`,
      description: `Are You looking for remote full-stack
      engineer Jobs? We hire dedicated
      fresher, junior& experienced web
      developers for clients with a 3-5 days
      simple process
      `
    },
    dataEngineer: {
      page: 'dataEngineer',
      title: `Hire Remote Big Data
      Engineer in India - Code1
      Dev
      `,
      description: `Looking for Remote full-time big Data
      Engineer jobs in India. We are
      CODE1 Dev Hiring IN ~7 Days both
      fresher and experienced for clients
      with a simple process.
      `
    },
    devops: {
      page: 'devops',
      title: `Hire Remote Devops
      Engineer With Easy
      Process - Code1 Dev`,
      description: `Explore top Aws DevOps Jobs
      opportunities from Top Companies &
      Startups. We are hiring remote
      Devops Engineer for a reputed MNC
      with 0-10 yrs experience`
    },
    dotNet: {
      page: 'dotNet',
      title: `Remote .NET Developer
      Jobs with Top Companies |
      Apply
      `,
      description: `Enjoy higher salaries with remote
      full-stack .NET developer jobs at top
      companies. We hire developers for
      the clients on full-time basis with a
      simple 7 days Process
      `
    },
    angular: {
      page: 'angular',
      title: `Hire Remote Angular JS
      Developers In 7 Days With
      Code1 Dev
      `,
      description: `Hire Remote freelance or work from
      Home Angular JS developers on the
      basis of a full-time job with 7 Days
      Hiring Process Work with the new
      technologies and skills
      `
    },
    sap: {
      page: 'sap',
      title: `Sap Remote Work From
      Home & Flexible Jobs |
      Code1 Dev
      `,
      description: `We Hire Remote SAP consultants
      developer/ jobs who implement SAP
      systems for business clients that
      determine the clients business
      needs, create SAP solutions`
    },

  };